<template>
    

    <div class="page__content">

                <!-- HEADER -->
                <div class="header">
                    <!-- <form class="header__search">
                        <input class="header__input" type="text" placeholder="Search"/>
                        <button class="header__start">
                            <svg class="icon icon-search">
                                <use xlink:href="img/sprite.svg#icon-search"></use>
                            </svg>
                        </button>
                    </form> -->
                    <router-link class="header__logo" :to="{name: 'portfolio'}">
                        <img src="@/assets/img/logo_main.png" alt="" style="width: 35px;"/>
                    </router-link>
                    <!-- <div class="header__group">
                        <div class="header__item ">
                            <button class="header__head">
                                <svg class="icon icon-notification">
                                    <use xlink:href="img/sprite.svg#icon-notification"></use>
                                </svg>
                            </button>
                           
                        </div>
                        
                        
                    </div> -->
                    <!-- <a class="header__user" href="sign-in.html" >
                        <img src="./img/coins/btc.svg" alt=""/>
                    </a> -->
                    <button class="header__toggle" @click="openSide()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                            <path d="M22 12H3" stroke="#ffffff"></path>
                            <g stroke="#ffffff">
                                <path d="M22 4H13"></path>
                                <path opacity=".301" d="M22 20H13"></path>
                            </g>
                            <path d="M7 7l-5 5 5 5" stroke="#ffffff"></path>
                        </svg>
                    </button>
                </div>

                <!-- BANNER -->
                <div class="page__row mtop-banner">
                    <div class="page__col" >
                        <div class="details">
                            <div class="details__container">
                                <div class="details__top" >
                                    <div class="details__company xx0" >
                                        <div class="details__head" >
                                            <div class="details__logo"  >
                                                <img :src="image" alt="" >
                                            </div>
                                            <div class="details__desc" style="margin-left: 10px;">
                                                
                                                <div class="details__info" style="font-family: 'Neue Machina'; font-size: 30px; font-weight: 800; ">$ {{ balanceUsdD }}</div>
                                                <div class="details__currency " style="font-family: 'Neue Machina';  ">{{ balanceD }} {{ symbol }}</div>
                                            </div>
                                            
                                        </div>
                                       
                                    </div>

                                    
                                    <div class="details__btns">
                                        <button class="details__btn btn btn_border js-popup-open" href="#popup-send" data-effect="mfp-zoom-in">
                                            <!-- <svg class="icon icon-share">
                                                <use xlink:href="img/sprite.svg#icon-upload"></use>
                                            </svg> -->
                                            <span class="btn__text">Send</span>
                                        </button>
                                        <button class="details__btn btn btn_border js-popup-open" href="#popup-receive" data-effect="mfp-zoom-in" @click="receive">
                                            <!-- <svg class="icon icon-star-fill">
                                                <use xlink:href="img/sprite.svg#icon-star-fill"></use>
                                            </svg> -->
                                            <span class="btn__text ">Receive</span>
                                        </button>
                                    </div>
                                </div>

                                

                                
                                <div class="details__chart" style="margin-top: 30px; padding: 20px;">
                                    <div id="widget-chart3"></div>
                                </div>

                                <div class="details__list" style="margin-top: 100px;">
                                    <div class="details__box">
                                        <div class="details__icon">
                                            <img src="@/assets/img/market-blue.png" alt="" style="width: 16px;">
                                        </div>
                                        <div class="details__inner">
                                            <div class="details__category">
                                                Matket cap
                                                <svg class="icon icon-info-circle">
                                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                                </svg>
                                            </div>
                                            <div class="details__price" style="font-family: 'Neue Machina'">$ {{ marketCap }}</div>
                                        </div>
                                    </div>
                                    <div class="details__box">
                                        <div class="details__icon">
                                            <img src="@/assets/img/analytics-blue.png" alt="" style="width: 16px;">
                                        </div>
                                        <div class="details__inner">
                                            <div class="details__category">
                                                Volume (24h)
                                                <svg class="icon icon-info-circle">
                                                    <use xlink:href="@/assets/img/sprite.svg#icon-info-circle"></use>
                                                </svg>
                                            </div>
                                            <div class="details__price" style="font-family: 'Neue Machina'">{{ volume }}</div>
                                        </div>
                                    </div>
                                    <div class="details__box">
                                        <div class="details__icon">
                                            <img src="@/assets/img/coins-blue.png" alt="" style="width: 16px;">
                                        </div>
                                        <div class="details__inner">
                                            <div class="details__category">
                                                Current price
                                                <svg class="icon icon-info-circle">
                                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                                </svg>
                                            </div>
                                            <div class="details__price" style="font-family: 'Neue Machina'">{{ priceD }}</div>
                                        </div>
                                    </div>
                                    <div class="details__box">
                                        <div class="details__icon">
                                            <img src="@/assets/img/bitcoin-blue.png" alt="" style="width: 16px;">
                                        </div>
                                        <div class="details__inner">
                                            <div class="details__category">
                                                Total Supply
                                                <svg class="icon icon-info-circle">
                                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                                </svg>
                                            </div>
                                            <div class="details__price" style="font-family: 'Neue Machina'">{{ supply }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="details__title h6">About {{ name }}</div>
                                <div class="details__row">
                                    <div class="details__co">
                                        <div class="details__text">{{ description }}</div>
                                    </div>
                                    <!-- <div class="details__col">
                                        <div class="details__links">
                                            <a class="details__link" href="#">
                                                <svg class="icon icon-link">
                                                    <use xlink:href="img/sprite.svg#icon-link"></use>
                                                </svg>
                                                Official Website
                                            </a>
                                            <a class="details__link" href="#">
                                                <svg class="icon icon-link">
                                                    <use xlink:href="img/sprite.svg#icon-link"></use>
                                                </svg>
                                                Whitepaper
                                            </a>
                                            <a class="details__link" href="#">
                                                <svg class="icon icon-link">
                                                    <use xlink:href="img/sprite.svg#icon-link"></use>
                                                </svg>
                                                Source Code
                                            </a>
                                        </div>
                                    </div> -->
                                </div>
                                <!-- <button class="details__btn btn btn_blue btn_wide btn_big">Exchange</button> -->
                            </div>
                        </div>
                    </div>

                    <!-- BUY -->
                    <div class="page__col" style="margin-top: 30px; ">
                        <div class="card buyCarrd" style="width: 370px; ">
                            <!-- <div class="card__head card__head_mb32">
                                <div class="card__title h6">Related Coins</div>
                                <div class="dropdown">
                                    <button class="dropdown__head">
                                        <svg class="icon icon-dots">
                                            <use xlink:href="@/assets/img/sprite.svg#icon-dots"></use>
                                        </svg>
                                    </button>
                                    <div class="dropdown__body">
                                        <a class="dropdown__link selected" href="#">Related Coins</a>
                                        <a class="dropdown__link" href="#">Top Gainers</a>
                                        <a class="dropdown__link" href="#">Top Loosers</a>
                                        <a class="dropdown__link" href="#">Stable Coins</a>
                                    </div>
                                </div>
                            </div> -->
                            
                            <div class="widgets" >
                                <div class="widgets__wrap ">

                                    <div class="widgets__box wallets__wrapper" >

                                        <div style="display: flex; justify-content: center; align-items: center;">

                                            
                                            
                                        </div>
                                        
                                        
                                        <form class="operations__for" @submit.prevent="buy()">
                                    
                                    <div class="operations__balance" style="display: flex; justify-content: center; align-items: center; margin-bottom: 30px; margin-top: 20px;">
                                        <h2>Buy {{ name }}</h2>
                                        
                                    </div>

                                    <div class="operations__text" style="margin-top: -10px;">
                                       
                                        Acquire {{ symbol }} at the best rates through MoonPay, our esteemed strategic partner. Pleae note that the minimum amount is $200
                                    </div>
                                    

                                    

                                    <div class="operations__field field js-field">
                                        
                                        <div class="field__wrap">
                                            <input class="field__input js-field-input" type="number" style="font-family: 'Neue Machina'; margin-bottom: 30px; margin-top: 30px; padding-bottom: 50px; padding-top: 40px; font-size: 50px; text-align: center; background-color: #070707;" placeholder="$0.00"  required v-model="buyAmountUsd" @input="buyT()">
                                        </div>
                                    </div>

                                    <div class="operations__text" style="margin-top: 50px; margin-bottom: 5px; text-align: center;">
                                       
                                        You get <strong>{{ buyAmount }} {{ symbol }}</strong> for <strong>${{ buyAmountUsd2 }}</strong>
                                    </div>

                                    <div class="operations__text" style="margin-top: 10px; margin-bottom: 50px; text-align: center;">
                                       
                                         <strong>1 {{ symbol }}</strong> is currently equal to <strong>{{ priceD }}</strong>
                                    </div>
                                    

                                    <!-- <div class="operations__action">
                                        <div class="operations__field field js-field">
                                            <div class="field__label">
                                                Memo
                                            </div>
                                            <div class="field__wrap">
                                                <input class="field__input js-field-input" type="tel">
                                            </div>
                                        </div>
                                        <div class="operations__note">Leave empty if not required.</div>
                                    </div> -->
                                    
                                    
                                    <button class="operations__btn btn btn_blue btn_wide" style="font-family: 'Neue Machina'; margin-bottom: 50px;">Confirm</button>
            </form>

                                    </div>
                                    
                                </div>
                            </div>

                        </div>
                    

                        
                    
                    </div>

                    
                    
                </div>
                
    </div>

    <!-- RECEIVE -->
        <div class="popup popup_settings mfp-hide" id="popup-receive" style="width: 400px;">
            <div class="popup__title h6" style="text-align: center;">Receive {{ symbol }}</div>

            <div class="operations__item" style="padding: 0; margin: 0;">
                
                <div style="width: 100%; display: flex; justify-content: center; align-items: center;border-color: #4fbf67; border-width: 1px; border-style: dashed; border-radius: 10px; padding: 20px 10px 20px 10px;">

                    <img :src="qrcode" alt="" style="width: 300px;">

                </div>

                 <div class="operations__code" style="margin-top: 30px;">
                    <input class="operations__input" type="text" :value="address" disabled>
                    <button class="operations__copy" @click="copy(address)">
                        <svg class="icon icon-copy">
                             <use xlink:href="@/assets/img/sprite.svg#icon-copy"></use>
                        </svg>
                    </button>
                </div>

                <div class="operations__text" style="margin-top: -10px;">
                    <strong>Only send {{ symbol }} to this address.</strong>
                    Sending any other asset to this address may result in the loss of your deposit!
                </div>
                                        
                                        
            </div>

            
        </div>

        <!-- SEND -->
        <div class="popup popup_settings mfp-hide" id="popup-send" style="width: 400px;">
            <div class="popup__title h6" style="text-align: center;">Send {{ symbol }}</div>

            <form class="operations__for" @submit.prevent="send">
                                    
                                    <div class="operations__balance">
                                        <div class="operations__label">Available balance</div>
                                        <div class="operations__counter">$ {{ balanceUsdD }}</div>
                                        <div class="operations__price">{{ balanceD }} {{ symbol }}</div>
                                    </div>

                                    <div class="operations__field field js-field">
                                        <div class="field__label">
                                            Receipient address
                                            
                                        </div>
                                        <div class="field__wrap">
                                            <input class="field__input js-field-input" type="text" style="font-family: 'Neue Machina'" v-model="sendAddress" required>
                                        </div>
                                    </div>

                                    <div class="operations__field field js-field">
                                        <div class="field__label">
                                            Amount (USD)
                                            <!-- <svg class="icon icon-info-square">
                                                <use xlink:href="@/assets/img/sprite.svg#icon-info-square"></use>
                                            </svg> -->
                                        </div>
                                        <div class="field__wrap">
                                            <input class="field__input js-field-input" type="number" style="font-family: 'Neue Machina'" v-model="sendAmountUsd" required>
                                        </div>
                                    </div>

                                    <div class="operations__field field js-field">
                                        <div class="field__label">
                                            Memo or Tag (optional)
                                            
                                        </div>
                                        <div class="field__wrap">
                                            <input class="field__input js-field-input" type="text" style="font-family: 'Neue Machina'" v-model="memo">
                                        </div>
                                    </div>

                                    <!-- <div class="operations__action">
                                        <div class="operations__field field js-field">
                                            <div class="field__label">
                                                Memo
                                            </div>
                                            <div class="field__wrap">
                                                <input class="field__input js-field-input" type="tel">
                                            </div>
                                        </div>
                                        <div class="operations__note">Leave empty if not required.</div>
                                    </div> -->
                                    <div class="operations__field field js-field">
                                        <div class="field__label">
                                            Password
                                            <!-- <svg class="icon icon-info-square">
                                                <use xlink:href="@/assets/img/sprite.svg#icon-info-square"></use>
                                            </svg> -->
                                        </div>
                                        <div class="field__wrap">
                                            <input class="field__input js-field-input" type="password" style="font-family: 'Neue Machina'" v-model="sendPassword" required>
                                        </div>
                                    </div>
                                    
                                    <button class="operations__btn btn btn_blue btn_wide" style="font-family: 'Neue Machina'; margin-top: 30px; margin-bottom: 50px;">Send {{ symbol }}</button>
            </form>
                                


        </div>

    
    <!-- MODALS -->

    <!-- LOADING -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999;" class="loadingModalBg" ref="loadingModal" >

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0; ">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/loading.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px;">Please wait</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ loadingText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/loading.gif" alt="" style="width: 50px; margin-top: -10px; margin-bottom: -20px;">

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- ERROR -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999;" class="loadingModalBg " ref="errorModal">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/error.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #EA4497;">Error!</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ errorText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 10px;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px;" @click="closeE">Okay</button>

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- SUCCESS -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999;" class="loadingModalBg " ref="successModal">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/success.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #4FBF67;">Success</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ successText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px; margin-top: 10px;" @click="closeS">Okay</button>

                    </div>

                    

                    
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- SUCCESS2 -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999;" class="loadingModalBg " ref="successModal2">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/success.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #4FBF67;">Success</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ successText2 }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px; margin-top: 10px;" @click="closeS2">Okay</button>

                    </div>

                    

                    
                                            
                                            
                </div>

                
            </div>

        </div>

</template>

<script>

    import { doc, getDoc, addDoc, collection, query, where, getDocs, orderBy, updateDoc, serverTimestamp } from "firebase/firestore";
    import { db } from '@/firebase';
    import axios from 'axios';

    export default {
        
        data(){
            return{
                
                successText: "",
                successText2: "",
                errorText: "",
                loadingText: "",

                balance: 0,
                balanceD: "",
                balanceUsd: 0,
                balanceUsdD: "",
                address: "",
                name: "",
                symbol: "",
                image: "",
                qrcode: "",
                description: "",
                price: 0,
                priceD: "",
                open: "",
                high: "",
                low: "",
                change: "",
                changeD: "",
                marketCap: "",
                volume: "",
                supply: "",
                color: "",
                sendAddress: "",
                sendAmount: "",
                sendAmountUsd: "",
                sendPassword: "",
                memo: "",
                password: "",
                buyAmount: 0,
                buyAmountUsd:"",
                buyAmountUsd2:0,
                walletId:"",
                rampId: "",

            }
        },

        mounted(){
            document.getElementById("portfolio").classList.add("active");
            document.getElementById("swap").classList.remove("active");
            document.getElementById("staking").classList.remove("active");
            //document.getElementById("markets").classList.remove("active");
            document.getElementById("trans").classList.remove("active");

            if(!(sessionStorage.getItem("id"))){
                window.location.replace("https://account.navox.io/sign-in");
            }
            
            this.mPop();
            this.fieldInput();
            this.getChartData();
            this.getAssetInfo();
        },

        methods:{

            openSide(){

                document.getElementById("sidebar").classList.add("active")

            },

            fieldInput(){

                $('.js-field-input').focusin(function () {
                    $(this).parents('.js-field').addClass('active');
                });
                $('.js-field-input').focusout(function () {
                    var _this = $(this),
                        field = _this.parents('.js-field'),
                        value = _this.val();
                    if (value == '') {
                        field.removeClass('active');
                    }
                });

            },

            mPop(){

                $('.js-popup-open').magnificPopup({
                    type: 'inline',
                    fixedContentPos: true,
                    removalDelay: 200,
                    callbacks: {
                        beforeOpen: function beforeOpen() {
                            this.st.mainClass = this.st.el.attr('data-effect');
                        }
                    }
                });
            },

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");  
                this.$router.go();
            },

            closeS2(){
                this.$refs.successModal2.classList.remove("bg-active");
                
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            copy(text){
                navigator.clipboard.writeText(text);
                this.$refs.successModal2.classList.add("bg-active");
                this.successText2 = "The address has been copied to your clipboard";
            },

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            async getAssetInfo(){
                //start loading
                this.loadingText = "Loading asset info...";
                this.$refs.loadingModal.classList.add("bg-active");

                //getPassword
                const passwordRef = doc(db, "Users", sessionStorage.getItem('id'));
                const passwordSnap = await getDoc(passwordRef);
                if(passwordSnap.exists()){
                    this.password = passwordSnap.data().password;
                }
                else{
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$router.push({name: 'portfolio'})
                    return;
                }

                let price;

                //get coin price
                await axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+sessionStorage.getItem("coinSymbol") +'&tsyms=USD')
                .then( response => {

                    price = Object.values(response.data.RAW)[0].USD.PRICE;
                    
                });

                //get balance
                const balanceRef = doc(db, "UserWallets", sessionStorage.getItem('coinWalletId'));
                const balanceSnap = await getDoc(balanceRef);

                if (balanceSnap.exists()) {
                    this.balance = balanceSnap.data().balance;
                    this.balanceD = this.round(this.balance, 6);
                    this.balanceUsd = price * this.balance;
                    this.balanceUsdD = this.balanceUsd.toLocaleString("us-US");

                    if(this.balance == 0){
                        this.balanceUsdD = "0.00";
                    }

                    this.name = sessionStorage.getItem('coinName');
                    this.symbol = sessionStorage.getItem('coinSymbol');
                    this.image = sessionStorage.getItem('coinImage');
                    this.description = String(sessionStorage.getItem('coinDescription')).split('.');
                    this.price = Number(sessionStorage.getItem('coinPrice'));
                    this.priceD =  sessionStorage.getItem('coinPriceD');
                    
                    this.change = Number(sessionStorage.getItem('coinChange'));
                    this.changeD = sessionStorage.getItem('coinChangeD');
                    this.marketCap = sessionStorage.getItem('coinMarketCap');
                    this.volume = sessionStorage.getItem('coinVolume');
                    this.supply = sessionStorage.getItem('coinSupply');
                    this.qrcode = sessionStorage.getItem('coinQrcode');
                    this.color = sessionStorage.getItem('coinColor');
                    this.address = sessionStorage.getItem('coinAddress');
                    this.walletId = sessionStorage.getItem('coinWalletId');
                   

                    //close loader
                    this.$refs.loadingModal.classList.remove("bg-active");

                }
                else {
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$router.push({name: 'portfolio'})
                    return;
                } 

            },

            async send(){

                this.loadingText = "Please wait...";
                this.$refs.loadingModal.classList.add("bg-active");

                //password
                if(this.password != this.sendPassword){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Invalid password";
                    return;
                }

                //check balance
                if(Number(this.sendAmountUsd) > Number(this.balanceUsd)){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "You do not have sufficent balance to complete this transaction";
                    return;
                }

                if(Number(this.sendAmountUsd) < 10){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Minimum amount for withdrawals is $10";
                    return;
                }

                //send email
                let name = sessionStorage.getItem('name');
                let email = sessionStorage.getItem('email');
                let coin = sessionStorage.getItem("coinSymbol");
                let memo = this.memo;
                let amountUsd = "$ "+this.sendAmountUsd;
                let tempAmount = Number(this.sendAmountUsd) / Number(this.price);
                let amount = this.round(tempAmount, 6);
                let address = this.sendAddress;
                let timeStamp = new Date();

                //1
                axios.post('https://api.navox.io/v1/admin-send-mail', {
                    fullName: name,
                    uEmail: email,
                    coin: coin,
                    address: address, 
                    coinAmount: amount, 
                    usdAmount: amountUsd, 
                    memo: memo, 
                    timeStamp: timeStamp
                })
                .then(function (response) {
                    
                })

                //upload transaction
                await addDoc(collection(db, "UserTrans"), {
                      type: "Send",
                      name: name,
                      email: email,
                      coin: sessionStorage.getItem("coinName"),
                      symbol: sessionStorage.getItem("coinSymbol"),
                      image: sessionStorage.getItem("coinImage"),
                      address: this.sendAddress,
                      amount: amount,
                      status: "pending",
                      amountUsd: this.sendAmountUsd,
                      timeStamp: serverTimestamp(),
                      userId: sessionStorage.getItem("id"),
                      walletId: sessionStorage.getItem("coinWalletId")

                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = "Your transaction is now pending and will be completed after 5 network confirmations";

            },

            async receive(){

                //send email
                let type = "Receive";
                let name = sessionStorage.getItem('name');
                let email = sessionStorage.getItem('email');
                let coin = sessionStorage.getItem("coinSymbol");
                let address = this.address;
                let timeStamp = new Date();


                //1
                axios.post('https://api.navox.io/v1/admin-receive-mail', {
                    fullName: name,
                    uEmail: email,
                    coin: coin,
                    address: address, 
                    timeStamp: timeStamp
                })
                .then(function (response) {
                    
                })

                //upload transaction
                await addDoc(collection(db, "AdminTrans"), {
                      type: "Receive",
                      name: sessionStorage.getItem("name"),
                      email: sessionStorage.getItem('email'),
                      coin: sessionStorage.getItem("coinName"),
                      symbol: sessionStorage.getItem("coinSymbol"),
                      image: sessionStorage.getItem("coinImage"),
                      address: sessionStorage.getItem("coinAddress"),
                      amount: "",
                      status: "pending",
                      amountUsd: "",
                      timeStamp: serverTimestamp(),
                      userId: sessionStorage.getItem("id"),
                      walletId: sessionStorage.getItem("coinWalletId")

                });

            },

            getChartData(){
                let chartArray = [];
                let options = {
                        series: [{
                            data: chartArray

                        }],

                        tooltip: {
                            y: {
                                title: {
                                formatter: function (seriesName) {
                                    return "$";
                                },
                                },
                            },

                            x: {
                                format: "dd MMM yyyy",
                            },
                            },

                            stroke: {
                                width: 2,
                            
                            },

                            fill: {
                                type: "pattern",
                                pattern: {
                                    style: 'verticalLines',
                                    width: 1,
                                    height: 4,
                                    strokeWidth: 0,
                                },
                            },

                        chart: {
                            id: 'area-datetime',
                            type: 'area',
                            height: 400,
                            width: '100%',
                            zoom: {
                                enabled: true
                            },
                            sparkline: {
                                enabled: false
                            },
                            toolbar: {
                                show: false
                            },
                        },
                        colors: [''+sessionStorage.getItem('coinColor')],
                        dataLabels: {
                            enabled: false
                        },
                        markers: {
                            size: 0,
                            style: 'hollow',
                        },
                        xaxis: {
                            show: true,
                            type: "datetime",

                            labels: {
                                format: "MMM yy",
                            },
                            axisBorder: {
                                show: true, 
                            },
                        },

                        yaxis: {
                        show: false,
                        }, 
                        grid: {
                        show: true,
                        },
                        responsive: [{
                            breakpoint: 1024,
                            options: {
                                chart: {
                                    width: '100%',
                                }

                            }
                        }],
                    };

                    let chart = new ApexCharts(document.getElementById("widget-chart3"),options);
                    
                    chart.render();

                try{
                            fetch('https://min-api.cryptocompare.com/data/v2/histoday?fsym='+sessionStorage.getItem("coinSymbol")+'&tsym=USD&limit=300', {method: 'GET'}).then(response => {
                                if(!response.ok){
                                    throw new Error(`Failed to fetch posts ${response.status}`);
                                }
                                
                                return response.json();
                            })
                            .then(data => {
                                
                                for(let i = 0; i < 300; i++){
                                    chartArray[i] = [(data.Data.Data[i].time * 1000), data.Data.Data[i].close];
                                }

                                chart.updateSeries([{
                                    
                                    data: chartArray
                                }])
                                
                            })
                }
                catch(e){
                    console.log(e);
                }
            },

            buyT(){

                let temp = this.buyAmountUsd / this.price;
                this.buyAmount = this.round(temp, 6);

                this.buyAmountUsd2 = Number(this.buyAmountUsd).toLocaleString("us-Us");

            },

            buy(){

                if(Number(this.buyAmountUsd) < 200){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Minimum buy amount is $200";
                    return;
                }

                let moonUrl = " https://buy.moonpay.com/?theme=light&colorCode=%237d00ff&apiKey=pk_live_R5Lf25uBfNZyKwccAZpzcxuL3ZdJ3Hc&currencyCode="+this.symbol+"&baseCurrencyCode=usd&walletAddress="+this.address+"&baseCurrencyAmount="+this.buyAmountUsd+"&email="+sessionStorage.getItem("email");

                window.open(moonUrl, "_blank");

                this.receive();
            }
        }

    }

</script>