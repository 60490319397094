<template>
  <AssetBody/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AssetBody from '@/components/AssetBody.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'AssetView',
  components: {
    AssetBody,
  },
});
</script>
