<template>
  <StakingBody/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StakingBody from '@/components/StakingBody.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'StakingView',
  components: {
    StakingBody,
  },
});
</script>
