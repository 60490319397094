<template>
  <SwapBody/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SwapBody from '@/components/SwapBody.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'SwapView',
  components: {
    SwapBody,
  },
});
</script>
