<template>
    
    <div class="page__content">

                <!-- HEADER -->
                <div class="header">
                    <!-- <form class="header__search">
                        <input class="header__input" type="text" placeholder="Search"/>
                        <button class="header__start">
                            <svg class="icon icon-search">
                                <use xlink:href="img/sprite.svg#icon-search"></use>
                            </svg>
                        </button>
                    </form> -->
                    <router-link class="header__logo" :to="{name: 'portfolio'}">
                        <img src="@/assets/img/logo_main.png" alt="" style="width: 30px;"/>
                    </router-link>
                    <!-- <div class="header__group">
                        <div class="header__item ">
                            <button class="header__head">
                                <svg class="icon icon-notification">
                                    <use xlink:href="img/sprite.svg#icon-notification"></use>
                                </svg>
                            </button>
                           
                        </div>
                        
                        
                    </div> -->
                    <!-- <a class="header__user" href="sign-in.html" >
                        <img src="./img/coins/btc.svg" alt=""/>
                    </a> -->
                    <button class="header__toggle" @click="openSide()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                            <path d="M22 12H3" stroke="#ffffff"></path>
                            <g stroke="#ffffff">
                                <path d="M22 4H13"></path>
                                <path opacity=".301" d="M22 20H13"></path>
                            </g>
                            <path d="M7 7l-5 5 5 5" stroke="#ffffff"></path>
                        </svg>
                    </button>
                </div>

                <!-- BANNER -->
                <div class="wallets mtop-banner">
                    <div class="wallets__container">
                        <div class="wallets__wrapper" id="bner">
                           
                            <div class="wallets__inner">
                                <div class="wallets__list" >
                                    
                                    <div style="display: flex; justify-content: center; align-items: flex-start; flex-direction: column; margin-left: 10px; margin-right: 10px; width: 100%;">
                                        <div class="wallets__info" >Total sent</div>
                                        <div class="wallets__number h5 " style="width: 100%;">$ {{ totalSentD }}</div>
                                        
                                    </div>

                                   <div class="vl" style="margin-right: 100px;"></div>

                                    <div style="display: flex; justify-content: center; align-items: flex-start; flex-direction: column; margin-left: 10px; margin-right: 10px; width: 100%;">
                                        <div class="wallets__info">Total received</div>
                                        <div class="wallets__number h5 " style="width: 100%;">$ {{ totalReceivedD }}</div>
                                        
                                    </div>

                                    <!-- <div class="vl mm-none"></div> -->

                                    <!-- <div style="display: flex; justify-content: center; align-items: flex-start; flex-direction: column; margin-left: 10px; margin-right: 10px; width: 100%;" class="mm-none">
                                        <div class="wallets__info mm-none">Successful</div>
                                        <div class="wallets__number h5 mm-none"> {{ totalSuccessful }}</div>
                                        
                                    </div> -->

                                    

                                    <!-- <div class="vl mm-none"></div>

                                    <div style="display: flex; justify-content: center; align-items: flex-start; flex-direction: column; margin-left: 20px; margin-right: 20px;" >
                                        <div class="wallets__info mm-none">Pending</div>
                                        <div class="wallets__number h5 mm-none"> {{ totalPending }}</div>
                                        
                                    </div> -->

                                     

                                    <!-- <div class="wallets__item">
                                        
                                        <div class="wallets__details">
                                            <div class="wallets__info">Locked balance</div>
                                            <div class="wallets__number h6">0.256567545 BTC</div>
                                            <div class="wallets__price">3,700.96 USD</div>
                                        </div>
                                    </div> -->

                                    
                                </div>

                                
                            </div>
                            
                        </div>

                        <div class="wallets__wrapper mtop-table" style="background-color: #070707; " id="yesT">
                            
                            

                            <div class="balances">
                            <div class="balances__title h6" style="margin-top: 15px;"> Transactions </div>
                            <div class="operations__text" style="margin-top: -20px;">Select a transaction to see its details</div>
                            <div class="operations__field field js-field">
                                <!-- <div class="field__label">Search asset name...</div> -->
                                <div class="field__wrap" style="margin-top: 5px; padding-bottom: 20px;"><input class="field__input js-field-input" placeholder="Search asset name or symbol..." v-model="search"  type="text" style="height: 50px; padding-bottom: 18px; font-family: 'Neue Machina';" ></div>
                            </div>
                            <div class="balances__table js-popup-open" href="#popup-trans" data-effect="mfp-zoom-in">
                                <div class="balances__row balances__row_head">
                                    <div class="balances__cell"></div>
                                    <div class="balances__cell" >Asset</div>
                                    <div class="balances__cell">Type</div>
                                    <div class="balances__cell">STATUS</div>
                                    <div class="balances__cell">ADDRESS</div>
                                    <div class="balances__cell">DATE</div>
                                    <div class="balances__cell balTT">AMOUNT</div>
                                    
                                </div>

                                <div class="balances__row "  v-for="trans in filteredTransList" :key="trans.id" @click="details(trans)">
                                    <div class="balances__cell">
                                        <img v-if="trans.type == 'Send'" src="../assets/img/sendArrow.png" alt="" style="width: 25px;">
                                        <img v-else src="../assets/img/receiveArrow.png" alt="" style="width: 25px;">
                                    </div>
                                    <div class="balances__cell">
                                        <div class="balances__company">
                                            <div class="balances__logo">
                                                <img :src="trans.image">
                                            </div>
                                            <div class="balances__text">{{ trans.symbol }}</div>
                                           
                                        </div>
                                    </div>
                                    <div class="balances__cell">{{ trans.type }}</div>
                                    <div class="balances__cell">
                                        <div class="balances__number color-green" v-if="trans.status == 'successful'">Successful</div>
                                        <div class="balances__number color-orange" v-else-if="trans.status == 'pending'">Pending</div>
                                        <div class="balances__number color-red" v-else>Failed</div>
                                    </div>
                                    <div class="balances__cell">
                                        <div class="balances__number">{{ trans.address }}</div>
                                        <div class="balances__price"></div>
                                    </div>
                                    <div class="balances__cell">
                                        <div class="balances__number">{{ trans.timeStamp }}</div>
                                        <!-- <div class="balances__price">11:12 pm</div> -->
                                    </div>
                                    <div class="balances__cell">
                                        <div class="balances__number balTT">$ {{ trans.amountUsdD }}</div>
                                        <div class="balances__price balTT">{{ trans.amountD }} {{ trans.symbol }}</div>
                                    </div>
                                    
                                </div>

                                
                            </div>
                            <!-- <div class="balances__btns">
                                <button class="balances__btn btn btn_blue">Load more</button>
                            </div> -->
                            </div>

                            
                        </div>

                        <div id="noT" style="display: flex; justify-content: center; align-items: center; margin-top: 200px; flex-direction: column;">

                            <img src="../assets/img/empty.png" alt="" style="width: 200px; margin-bottom: 20px;">

                            <h2>No transactions yet...</h2>
                        </div>

                       

                    </div>


                    <!-- SIDEBAR -->
                    <div class="wallets__sidebar" style="margin-top: 50px;">
                        <div class="wallets__wrap">
                            <button class="wallets__close">
                                <svg class="icon icon-close">
                                    <use xlink:href="@/assets/img/sprite.svg#icon-close"></use>
                                </svg>
                            </button>
                            <div class="wallets__head">
                                <div class="wallets__logo">
                                    <img :src="image" alt="">
                                </div>
                                <div class="wallets__details">
                                    <div class="wallets__info">{{ coin }}</div>
                                    <div class="wallets__company">{{ symbol }}</div>
                                </div>
                            </div>
                            <div class="wallets__body">
                                
                                <div class="wallets__parameters">

                                    <div class="wallets__parameter">

                                        <div class="wallets__box">
                                            <div class="wallets__category">Type</div>
                                            <div class="wallets__number h6">{{ type }}</div>
                                            <div class="wallets__number"></div>
                                        </div>
                                    </div>

                                    <div class="wallets__parameter">

                                        <div class="wallets__box">
                                            <div class="wallets__category">Amount</div>
                                            <div class="wallets__number h6">$ {{ amountUsdD }}</div>
                                            <div class="wallets__number">{{ amountD }} {{ symbol }}</div>
                                        </div>
                                    </div>

                                    <div class="wallets__parameter">

                                        <div class="wallets__box">
                                            <div class="wallets__category">Status</div>
                                            <div class="wallets__number h6 color-green" v-if="status=='success'">Successful</div>
                                            <div class="wallets__number h6 color-orange" v-else-if="status=='pending'">Pending</div>
                                            <div class="wallets__number h6 color-red" v-else>Failed</div>
                                            <div class="wallets__price"></div>
                                        </div>
                                    </div>

                                    <div class="wallets__parameter">

                                        <div class="wallets__box">
                                            <div class="wallets__category">Address</div>
                                            <div class="wallets__number h6" >{{ address }}</div>
                                            <div class="wallets__price"></div>
                                        </div>
                                    </div>

                                    <div class="wallets__parameter">

                                        <div class="wallets__box">
                                            <div class="wallets__category">Date</div>
                                            <div class="wallets__number h6" >{{ date }}</div>
                                            <div class="wallets__price"></div>
                                        </div>
                                    </div>
                                    
                                </div>
                               
                            </div>
                            
                        </div>
                    </div>

                </div>
                

    </div>


    <!-- MODALS -->

    <!-- LOADING -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999;" class="loadingModalBg" ref="loadingModal" >

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0; ">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/loading.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px;">Please wait</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ loadingText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/loading.gif" alt="" style="width: 50px; margin-top: -10px; margin-bottom: -20px;">

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- ERROR -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999;" class="loadingModalBg " ref="errorModal">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/error.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #EA4497;">Error!</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ errorText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 10px;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px;" @click="closeE">Okay</button>

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- SUCCESS -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999;" class="loadingModalBg " ref="successModal">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/success.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #4FBF67;">Success</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ successText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px; margin-top: 10px;" @click="closeS">Okay</button>

                    </div>

                    

                    
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- TRANS MODAL -->
        <div class="popup popup_settings mfp-hide" id="popup-trans" style="width: 400px;">
            <div class="popup__title h6" style="text-align: center;">{{ type }} {{ symbol }}</div>

            <div class="operations__item" style="padding: 0; margin: 0;">
                

                <div class="activity__box" >
                    <div class="activity__category" >Amount</div>
                    <div class="activity__value" style="font-size: 14px;">{{ amountD }} {{ symbol }}</div>
                </div>

                <br>
                <hr style="opacity: 0.1;">
                <br>

                <div class="activity__box" >
                    <div class="activity__category" > Amount(USD) </div>
                    <div class="activity__value" style="font-size: 14px;"> ${{ amountUsdD }} </div>
                </div>

                <br>
                <hr style="opacity: 0.1;">
                <br>

                <div class="activity__box" >
                    <div class="activity__category" >Address</div>
                    <div class="activity__value" style="font-size: 14px; word-break: break-all;">{{ address }}</div>
                </div>

                <br>
                <hr style="opacity: 0.1;">
                <br>

                <div class="activity__box" >
                    <div class="activity__category" >Status</div>
                    <div class="activity__value color-green" v-if="status == 'successful'" style="font-size: 14px;">{{ status }}</div>
                    <div class="activity__value color-yellow" v-else-if="status == 'pending'" style="font-size: 14px;">{{ status }}</div>
                    <div class="activity__value color-red" v-else style="font-size: 14px;">{{ status }}</div>
                </div>

                <br>
                <hr style="opacity: 0.1;">
                <br>

                <div class="activity__box" >
                    <div class="activity__category" >Date</div>
                    <div class="activity__value" style="font-size: 14px;">{{ date }}</div>
                </div>
                                        
                                        
            </div>

            
        </div>

</template>

<script>

    import { doc, getDoc, addDoc, collection, query, where, getDocs, orderBy, updateDoc, serverTimestamp } from "firebase/firestore";
    import { db } from '@/firebase';
    import axios from 'axios';

    export default {

        data(){
            return{

                successText: "",
                errorText: "",
                loadingText: "",

                totalSent: 0,
                totalReceived: 0,
                totalSentD: "",
                totalReceivedD: "",
                totalSuccessful: 0,
                totalPending: 0,

                transList: [],
                search: "",

                coin: "",
                symbol: "",
                image: "",
                status: "",
                type: "",
                amount: 0,
                amountD: "",
                amountUsd: 0,
                amountUsdD: "",
                address: "",
                date: "",

            }
        },

        
        mounted(){
            document.getElementById("portfolio").classList.remove("active");
            document.getElementById("swap").classList.remove("active");
            document.getElementById("staking").classList.remove("active");
            //document.getElementById("markets").classList.remove("active");
            document.getElementById("trans").classList.add("active");

            if(!(sessionStorage.getItem("id"))){
                window.location.replace("https://account.navox.io/sign-in");
            }

            this.mPop();

            this.getTrans();
            
        },

        methods:{

            openSide(){

                document.getElementById("sidebar").classList.add("active")

            },

            mPop(){

                $('.js-popup-open').magnificPopup({
                    type: 'inline',
                    fixedContentPos: true,
                    removalDelay: 200,
                    callbacks: {
                        beforeOpen: function beforeOpen() {
                            this.st.mainClass = this.st.el.attr('data-effect');
                        }
                    }
                });
            },

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");  
                this.$router.go();
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            async getTrans(){

                this.loadingText= "Getting transactions...";
                this.$refs.loadingModal.classList.add("bg-active");

                const q = query(collection(db, "UserTrans"), where("userId", "==", sessionStorage.getItem("id")), orderBy("timeStamp", "desc")); 
                const querySnapshot = await getDocs(q);

                if(querySnapshot.docs.length > 0){
                    let i = -1;
                    let a2;

                    querySnapshot.forEach((doc) => {
                        i++;
                        
                        a2 = String(new Date(Number(doc.data().timeStamp.seconds) * 1000));
                        
                        //sent
                        if(doc.data().type == "Send" && doc.data().status == "successful"){
                            this.totalSent += doc.data().amountUsd;
                        }

                        //receive
                        if(doc.data().type == "Receive"){
                            this.totalReceived += doc.data().amountUsd;
                        }

                        //success
                        if(doc.data().status == "successful"){
                            this.totalSuccessful++;
                        }

                        //pending
                        if(doc.data().status == "pending"){
                            this.totalPending++;
                        }

                        this.transList[i] = {
                            number: i,
                            id: doc.id,
                            type: doc.data().type,
                            name: doc.data().name,
                            email: doc.data().email,
                            coin: doc.data().coin,
                            symbol: doc.data().symbol,
                            image: doc.data().image,
                            address: doc.data().address,
                            amount: doc.data().amount,
                            amountD: this.round(doc.data().amount, 6),
                            status: doc.data().status,
                            amountUsd: doc.data().amountUsd,
                            amountUsdD: Number(doc.data().amountUsd).toLocaleString("us-US"),
                            timeStamp: a2.slice(0, -36),
                            
                        }

                    })

                    this.totalReceivedD = this.totalReceived.toLocaleString("us-US");
                    this.totalSentD = this.totalSent.toLocaleString("us-US");

                    // if(this.historyList.length > 0){
                    //     this.getTotal();
                    // }

                    document.getElementById("noT").style.display = "none";
                }
                else{
                 
                    document.getElementById("yesT").style.display = "none";
                    document.getElementById("bner").style.display = "none";
                
                    document.getElementById("noT").style.display = "flex";
                }

                this.$refs.loadingModal.classList.remove("bg-active");
            },

            async getTotal(){

                let temp;
                let temp2 = 0;
    
                let temp3;
                let temp4 = 0;

                let totalUsd;
                let totalLockedUsd;

                let num = 0;
                let totalApy = 0

                for(let i=0; i< this.transList.length; i++){

                    num++
                   
                    let tempSym;
                        tempSym = this.transList[i].symbol;

                        await axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+tempSym+'&tsyms=USD')
                        .then( response => {

                            let price = Number(Object.values(response.data.RAW)[0].USD.PRICE);

                            //rewards
                            let reward = this.historyList[i].reward;
                            temp = price * reward;
                            temp2 += temp; 
                            totalUsd = this.round(temp2, 2);
                            this.totalRewardUsd = totalUsd.toLocaleString("us-US");

                            //locked
                            let locked = this.historyList[i].lockedAmount;
                            temp3 = price * locked;
                            temp4 += temp3; 
                            totalLockedUsd = this.round(temp4, 2);
                            this.totalLockedUsd = totalLockedUsd.toLocaleString("us-US");

                            //apy
                            totalApy +=  this.historyList[i].apy;

                        }); 

                }
            

            },

            details(trans){

                this.coin = trans.coin;
                this.symbol = trans.symbol;
                this.image = trans.image;
                this.status = trans.status;
                this.type = trans.type;
                this.amount = trans.amount;
                this.amountD = this.round(trans.amount, 6)
                this.amountUsd = trans.amountUsd,
                this.amountUsdD = Number(this.amountUsd).toLocaleString("us-US");
                this.address = trans.address;
                this.date = trans.timeStamp;


            }

        },

        computed:{

            filteredTransList(){
                return this.transList.filter( hist => hist.coin.toLowerCase().includes(this.search.toLowerCase()) || hist.symbol.toLowerCase().includes(this.search.toLowerCase()) );
            },

        },
        
    }

</script>