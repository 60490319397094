<template>
  <PortfolioBody/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PortfolioBody from '@/components/PortfolioBody.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'PortfolioView',
  components: {
    PortfolioBody,
  },
});
</script>
