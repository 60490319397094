import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import PortfolioView from "../views/PortfolioView.vue";
import AssetView from '../views/AssetView.vue';
import SwapView from '../views/SwapView.vue';
import StakingView from '../views/StakingView.vue';
import TransactionView from '../views/TransactionView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "portfolio",
    component: PortfolioView,
  },

  {
    path: "/portfolio",
    name: "portfolio2",
    component: PortfolioView,
  },

  {
    path: "/asset",
    name: "asset",
    component: AssetView,
  },

  {
    path: "/swap",
    name: "swap",
    component: SwapView,
  },

  {
    path: "/staking",
    name: "staking",
    component: StakingView,
  },

  {
    path: "/transactions",
    name: "trans",
    component: TransactionView,
  },

  {
    path: "/markets",
    name: "markets",
    component: PortfolioView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition){
    return{
      top: 0
    }
  },
})

export default router
