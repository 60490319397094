<template>
  
  <!-- SIDEBAR -->
  <div class="sidebar" id="sidebar">
                <div class="sidebar__head sbbar" style="position: relative; ">
                    <!-- <a class="sidebar__logo" href="index.html" >
                        <img class="sidebar__pic sidebar__pic_light" src="./img/logo_long.png" alt="" style="width: 170px;"/>
                        <img class="sidebar__pic sidebar__pic_dark" src="img/logo_long.png" alt="" style="width: 170px;"/>
                        
                    </a> -->

                    <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; ">

                        <img :src="profilePic" id="profilePic" alt="" style="width: 80px; border-radius: 50%; margin-top: 100px; background-color: #484848; ">
                        <p style="margin-top: 10px; font-size: 18px;" id="profileName">{{ profileName }}</p>
                        <a  class="js-popup-open" href="#popup-settings" data-effect="mfp-zoom-in">
                            <div class="wallets__info2" style="color: #4fbf67;">Settings</div>
                        </a>

                    </div>
                    <!-- <button class="sidebar__toggle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                            <path d="M22 12H3" stroke="#11142d"></path>
                            <g stroke="#808191">
                                <path d="M22 4H13"></path>
                                <path opacity=".301" d="M22 20H13"></path>
                            </g>
                            <path d="M7 7l-5 5 5 5" stroke="#11142d"></path>
                        </svg>
                    </button> -->
                    <button class="sidebar__close" style="position: absolute; right: 5px; top: 0;" @click="closeSide()">
                        <svg class="icon icon-close">
                            <use xlink:href="@/assets/img/sprite.svg#icon-close"></use>
                        </svg>
                    </button>
                </div>
                <div class="sidebar__body" style="margin-top: 100px;">

                  

                    <nav class="sidebar__nav">
                        <div class="hl"></div>

                        <router-link  @click="closeSide()" :to="{name: 'portfolio'}" id="portfolio" class="sidebar__item " style="margin-top: 15px;">
                            <div class="sidebar__icon">
                                <svg class="icon icon-wallet">
                                    <use xlink:href="@/assets/img/sprite.svg#icon-wallet"></use>
                                </svg>
                            </div>
                            <div class="sidebar__text">Portfolio</div>
                        </router-link>

                        <router-link @click="closeSide()" :to="{name: 'swap'}" id="swap" class="sidebar__item" style="margin-top: 10px;">
                            <div class="sidebar__icon">
                                <svg class="icon icon-discount">
                                    <use xlink:href="@/assets/img/sprite.svg#icon-home"></use>
                                </svg>
                            </div>
                            <div class="sidebar__text">Swap</div>
                        </router-link>

                        <router-link @click="closeSide()" :to="{name: 'staking'}" id="staking" class="sidebar__item"  style="margin-top: 10px;">
                            <div class="sidebar__icon">
                                <svg class="icon icon-activity">
                                    <use xlink:href="@/assets/img/sprite.svg#icon-activity"></use>
                                </svg>
                            </div>
                            <div class="sidebar__text">Staking</div>
                        </router-link>

                        <router-link @click="closeSide()" :to="{name: 'trans'}" id="trans" class="sidebar__item" style="margin-top: 10px;">
                            <div class="sidebar__icon">
                                <svg class="icon icon-notification">
                                    <use xlink:href="@/assets/img/sprite.svg#icon-document"></use>
                                </svg>
                            </div>
                            <div class="sidebar__text">Transactions</div>
                        </router-link>

                        <!-- <router-link @click="closeSide()" :to="{name: 'markets'}" id="markets" class="sidebar__item" style="margin-top: 10px;">
                            <div class="sidebar__icon">
                                <svg class="icon icon-settings">
                                    <use xlink:href="@/assets/img/sprite.svg#icon-calendar"></use>
                                </svg>
                            </div>
                            <div class="sidebar__text">Markets</div>
                        </router-link> -->

                        <a href="https://account.navox.io/sign-in" class="sidebar__item" style="margin-top: 10px;">
                            <div class="sidebar__icon">
                                <svg class="icon icon-settings">
                                    <use xlink:href="@/assets/img/sprite.svg#icon-arrow-down-square"></use>
                                </svg>
                            </div>
                            <div class="sidebar__text">Sign out</div>
                        </a>
                    </nav>
                    
                </div>
                
  </div>

  <router-view/>


  <!-- MODALS -->

  <!-- SETTINGS -->
  <div class="popup popup_settings mfp-hide" id="popup-settings" style="z-index: 9999;">
            <div class="popup__title h6">Account Settings</div>
            <div class="popup__tabs js-tabs">
                <div class="popup__select mobile-show">
                    <select class="select js-tabs-select">
                        <option>Profile</option>
                        <option>Referrals</option>
                        <option>Password</option>
                        <option>2FA</option>
                        <option>Recovery phrase</option>
                    </select>
                </div>
                <div class="popup__nav">
                    <a class="popup__link js-tabs-link active" href="#">Profile</a>
                    <a class="popup__link js-tabs-link" href="#">Referrals</a>
                    <a class="popup__link js-tabs-link" href="#">Password</a>
                    <a class="popup__link js-tabs-link" href="#">2FA</a>
                    <a class="popup__link js-tabs-link" href="#">Recovery phrase</a>
                </div>
                <div class="popup__container">
                    <div class="popup__item js-tabs-item" style="display: block;">
                        <div class="popup__user">
                            <div class="popup__label popup__label_mb32">Your Avatar</div>
                            <div class="popup__line">
                                <div class="popup__ava">
                                    <img class="popup__pic" :src="profilePic" alt="">
                                </div>
                                <div class="popup__details">
                                    <div class="popup__btns">
                                        <div class="popup__loading" style="padding-top: 15px;">
                                            <button class="popup__upload" @click="openImage()">Upload New</button>
                                        </div>
                                        
                                    </div>
                                    <!-- <a class="popup__default" href="#">Use default avatar</a> -->
                                </div>
                            </div>
                        </div>
                        <div class="popup__fieldset">
                            <div class="popup__row">
                                <div class="popup__field">
                                    <div class="popup__label">Name</div>
                                    <div class="popup__wrap">
                                        <input class="popup__input" type="text" disabled :value="profileName">
                                    </div>
                                </div>
                                <div class="popup__field">
                                    <div class="popup__label">Email</div>
                                    <div class="popup__wrap">
                                        <input class="popup__input" type="email" disabled :value="email">
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>
                        
                        
                    </div>

                    <!-- REFERRAL -->
                    <div class="popup__item js-tabs-item">
                        <div class="popup__invite">
                            <div class="popup__info h6">Invite your friends to Navox and Earn 5% commission on their deposits</div>
                            <div class="popup__label">Your Referral Link</div>
                            <div class="popup__wrap">
                                <input class="popup__input" type="text" :value="refLink">
                                <button class="popup__copy" @click="copyLink()">
                                    <svg class="icon icon-copy">
                                        <use xlink:href="@/assets/img/sprite.svg#icon-copy"></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <!-- <div class="popup__category">Total Rewards</div>
                        <div class="popup__flex">
                            <div class="popup__money h3">128.256</div>
                            <div class="popup__currency">USTD</div>
                        </div> -->
                        <div class="popup__parameters">
                            <div class="popup__parameter">
                                <div class="popup__head">
                                    <div class="popup__text">Inviter Rewards</div>
                                    <div class="popup__text">${{ refBalance }}</div>
                                </div>
                                <div class="popup__body">You’re earning 5% of the deposits your referrals make.</div>
                            </div>
                            <div class="popup__parameter">
                                <div class="popup__head">
                                    <div class="popup__text">Total Invited</div>
                                    <div class="popup__text">{{ totalRefs }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- CHANGE PASSWORD -->
                    <div class="popup__item js-tabs-item">
                        <form class="popup__form" @submit.prevent="changePassword()">
                            <div class="popup__title h6">Change Password</div>
                            <div class="popup__field field js-field">
                                <div class="field__label">Current Password</div>
                                <div class="field__wrap">
                                    <input class="field__input js-field-input" type="password" v-model="oldPassword" required>
                                </div>
                            </div>
                            <div class="popup__field field js-field">
                                <div class="field__label">New Password</div>
                                <div class="field__wrap">
                                    <input class="field__input js-field-input" type="password" v-model="newPassword" required>
                                </div>
                            </div>
                            <div class="popup__field field js-field">
                                <div class="field__label">Confirm new Password</div>
                                <div class="field__wrap">
                                    <input class="field__input js-field-input" type="password" v-model="cNewPassword" required>
                                </div>
                            </div>
                            <button class="popup__btn btn btn_blue btn_wide">Change Password</button>
                        </form>
                    </div>

                    <!-- 2FA -->

                    <div class="popup__item js-tabs-item">
                        <form class="popup__form" @submit.prevent="update2fa()">
                            <div class="popup__title h6">2 Factor Authentication</div>
                            <div class="popup__details" style="margin-top: -20px;">
                                    <div class="operations__text">
                                        Enter your password to enable or disable two factor authentication for your account. We highly recommend that you enable this feature as it adds an extra layer of security to your account.
                                    </div>
                                    
                                </div>

                                <div class="popup__field field js-field" style="margin-top: 40px; margin-bottom: 50px;">
                                        <div class="field__label">Password</div>
                                        <div class="field__wrap">
                                            <input class="field__input js-field-input" type="password" v-model="faPassword" required>
                                        </div>
                                    </div>


                            
                            
                            <button class="popup__btn btn btn_blue btn_wide">{{ faStatus }}</button>
                        </form>
                    </div>

                    

                    <!-- PHRASE -->

                    <div class="popup__item js-tabs-item">
                        <form class="popup__form" @submit.prevent="openPhrase()">
                            <div class="popup__title h6">Recovery phrase</div>
                            <div class="popup__details" style="margin-top: -20px;">
                                    <div class="operations__text">
                                        Enter your password to view your 12 word recovery phrase. Your recovery phrase allows you to recover your Omicron wallet. Please do not expose or share your recovery phrase with anyone as doing so could result in your funds being stolen.
                                    </div>
                                    
                                </div>

                                <div class="popup__field field js-field" style="margin-top: 40px; margin-bottom: 50px;">
                                        <div class="field__label">Password</div>
                                        <div class="field__wrap">
                                            <input class="field__input js-field-input" type="password" v-model="recoveryPassword" required>
                                        </div>
                                    </div>


                            
                            
                            <button class="popup__btn btn btn_blue btn_wide">Show phrase</button>
                        </form>
                    </div>

                    
                </div>
            </div>
        </div>

  <!-- LOADING -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999;" class="loadingModalBg" ref="loadingModal" >

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0; ">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/loading.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px;">Please wait</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ loadingText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/loading.gif" alt="" style="width: 50px; margin-top: -10px; margin-bottom: -20px;">

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- ERROR -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999;" class="loadingModalBg " ref="errorModal">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/error.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #EA4497;">Error!</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ errorText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 10px;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px;" @click="closeE()">Okay</button>

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- SUCCESS -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999;" class="loadingModalBg " ref="successModal">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/success.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #4FBF67;">Success</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ successText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px; margin-top: 10px;" @click="closeS()">Okay</button>

                    </div>

                    

                    
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- SUCCESS2 -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999;" class="loadingModalBg " ref="successModal2">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/success.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #4FBF67;">Success</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ successText2 }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px; margin-top: 10px;" @click="closeS2()">Okay</button>

                    </div>

                    

                    
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- PHRASE -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999;" class="loadingModalBg" ref="phraseModal">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">

                    <div class="popup__title h6" style="text-align: center; margin-top: 10px; color: #fff;">12 word phrase</div>

                    <button class="mfp-close" @click="closeP()">x</button>
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center; flex-wrap: wrap;">

                        <div class="popup__title h6" style="text-align: center; margin-top: 0px; color: #4FBF67; font-weight: 900; word-spacing: 10px; line-height: 40px; font-size: 14px;">{{ phrase }}</div>

                    </div>

                    

                    

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px; margin-top: 10px;" @click="copyPhrase()">Copy</button>

                    </div>

                    

                    
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- EDIT IMAGE -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 99999;" class="loadingModalBg" ref="imageModal">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #4FBF67;">Update profile image</div>

                    <button class="mfp-close" @click="closeImage()">x</button>

                    <form @submit.prevent="updateImg()">
                    
                        <input class="field__input" type="file" id="imageFile" accept="image/*" required>

                    <div class="operations__field field js-field">
                                <!-- <div class="field__label">Search asset name...</div> -->
                                <div class="field__wrap" style="margin-top: 0px; padding-bottom: 20px;">
                                    <input class="field__input js-field-input" placeholder="Password"  type="password" style="height: 50px; padding-bottom: 18px; margin-top: 20px; font-family: 'Neue Machina';" v-model="imagePassword" required>
                                </div>
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px; margin-top: 20px;">Upload</button>

                    </div>
                    
                    </form>

                    

                    
                                            
                                            
                </div>

                
            </div>

        </div>

</template>

<style>
  @import url(./assets/css/app.css);
</style>

<script>

    import { doc, getDoc, addDoc, collection, query, where, getDocs, orderBy, updateDoc } from "firebase/firestore";
    import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
    import { db } from '@/firebase';

    export default {

        data(){
            return{
                profileName: "",
                email: "",
                profilePic: "",

                errorText: "",
                loadingText: "",
                successText: "",
                successText2: "",


                password: "",
                
                cNewPassword: "",
                newPassword: "",
                oldPassword: "",

                imagePassword: "",

                faStatus: "",
                faPassword: "",

                recoveryPassword: "",
                phrase: "",

                refLink: "",
                totalRefs: 0,
                refBalance: 0,
            }
        },
        
        mounted(){

            //set body
            document.body.classList.add('dark');

            this.options();
            this.fieldInput();
            this.getStats();


        },

        methods:{

            closeSide(){

                document.getElementById("sidebar").classList.remove("active");

            },

            

            fieldInput(){

                $('.js-field-input').focusin(function () {
                    $(this).parents('.js-field').addClass('active');
                });
                $('.js-field-input').focusout(function () {
                    var _this = $(this),
                        field = _this.parents('.js-field'),
                        value = _this.val();
                    if (value == '') {
                        field.removeClass('active');
                    }
                });

            },
            
            async getStats(){

                this.loadingText = "Preparing dashboard...";
                this.$refs.loadingModal.classList.add("bg-active");

                let urlString = String(window.location.href);
                let url = new URL(urlString);
                let userId = url.searchParams.get("id");

                
            
                if(userId != null){
                    sessionStorage.setItem("id", String(userId));
                }

                const docRef = doc(db, "Users", sessionStorage.getItem("id"));
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    

                    sessionStorage.setItem("name", String(docSnap.data().name));
                    sessionStorage.setItem("profilePic", String(docSnap.data().profilePic));
                    sessionStorage.setItem("email", String(docSnap.data().email));
                    sessionStorage.setItem("refCode", docSnap.data().refCode);

                    this.password = docSnap.data().password;

                    this.profileName = docSnap.data().name;
                    this.email = docSnap.data().email;
                    this.profilePic = docSnap.data().profilePic;
                    this.phrase = docSnap.data().phrase;

                    this.refLink = "https://account.navox.io/sign-up?ref="+docSnap.data().refCode;
                    this.refBalance = docSnap.data().refBalance;
                    this.totalRefs = docSnap.data().totalRefs;

                    if(docSnap.data().auth == "no"){
                        this.faStatus = "Enable 2FA";
                    }
                    else{
                        this.faStatus = "Disable 2FA";
                    }
                    
                    
                } 
                else {
                    this.$refs.loadingModal.classList.remove("bg-active");
                    window.location.replace("https://account.navox.io/sign-in");
                    return;
                }

                this.$refs.loadingModal.classList.remove("bg-active");

            },

            options(){

                let option = $('.js-tabs-select .option');
                let tabs = $('.js-tabs');
                tabs.each(function () {
                    let thisTabs = $(this),
                        nav = thisTabs.find('.js-tabs-link'),
                        option = thisTabs.find('.option'),
                        item = thisTabs.find('.js-tabs-item');
                    nav.on('click', function () {
                        let thisNav = $(this),
                            indexNav = thisNav.index();
                        nav.removeClass('active');
                        thisNav.addClass('active');
                        item.hide();
                        item.eq(indexNav).fadeIn();
                        return false;
                    });
                });

                

                

                option.on('click', function () {
                    let thisOption = $(this),
                        indexOption = thisOption.index();
                    console.log(indexOption);
                    $('.js-tabs-item').hide();
                    $('.js-tabs-item').eq(indexOption).fadeIn();
                });
            },

            openImage(){
                this.$refs.imageModal.classList.add("bg-active");  
            },

            closeImage(){
                this.$refs.imageModal.classList.remove("bg-active");  
            },

            closeP(){
                this.$refs.phraseModal.classList.remove("bg-active");  
            },

            copyPhrase(){
                navigator.clipboard.writeText(this.phrase);
                this.$refs.phraseModal.classList.remove("bg-active");
            },

            copyLink(){
                navigator.clipboard.writeText(this.refLink);
                this.successText2 = "Referral link has been copied to your clipboard"
                this.$refs.successModal2.classList.add("bg-active");
            },

            openPhrase(){

                //start loading
                this.loadingText= " Please wait...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                if(this.recoveryPassword != this.password ){
                    this.errorText = "Incorrect password"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.phraseModal.classList.add("bg-active");

            },

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");  
                this.$router.go();
            },

            closeS2(){
                this.$refs.successModal2.classList.remove("bg-active");  
        
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            async updateImg(){

                //start loading
                this.loadingText = "Uploading image..."
                this.$refs.loadingModal.classList.add("bg-active");

                let imageFile = document.getElementById("imageFile");

                if(this.imagePassword != this.password){
                    this.errorText = "Incorrect password";
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(imageFile.files[0].size > 3000000){
                    this.errorText = "Image is too large. Minimum size for image is 3MB";
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                const storage = getStorage();

                // Create the file metadata
                /** @type {any} */

                const metadata = {
                    contentType: 'image/*'
                };

                // Upload file and metadata to the object 'images/mountains.jpg'
                const storageRef = ref(storage, 'UserImages/profiles/' + imageFile.files[0].name);
                const uploadTask = uploadBytesResumable(storageRef, imageFile.files[0], metadata);

                // Listen for state changes, errors, and completion of the upload.
                uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                    }
                }, 
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        this.updatePic(downloadURL);
                        
                    });
                }); 


            },

            async updatePic(url){

                //update balance
                const userRef = doc(db, "Users", sessionStorage.getItem("id"));
                await updateDoc(userRef, {
                    profilePic: url
                });

                this.successText = "Image was uploaded successfully";
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");

            },

            async changePassword(){
                //start loading
                this.loadingText= "Updating password...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                if(this.password != this.oldPassword ){
                    this.errorText = "Incorrect password"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(this.newPassword != this.cNewPassword ){
                    this.errorText = "Passwords do not match"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(this.newPassword.length < 8 ){
                    this.errorText = "Password can not be less than 8 characters"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(this.newPassword == this.password ){
                    this.errorText = "New password can not be the same as old password"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                //update password
                const userRef = doc(db, "Users", sessionStorage.getItem("id"));
                await updateDoc(userRef, {
                    password: String(this.newPassword)
                });

                this.successText = "Password changed successfully"
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");

            },

            async update2fa(){
                //start loading
                this.loadingText= "Updating 2FA status...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                if(this.faPassword != this.password ){
                    this.errorText = "Incorrect password"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                //update status
                const userRef = doc(db, "Users", sessionStorage.getItem("id"));

                if(this.faStatus == "Enable 2FA"){
                    await updateDoc(userRef, {
                        auth: "yes"
                    });
                }
                else{
                    await updateDoc(userRef, {
                        auth: "no"
                    });
                }

                this.successText = "2FA status updated successfully"
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                

            },

        }

    }
</script>
