<template>
  <TransactionBody/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TransactionBody from '@/components/TransactionBody.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'TransactionView',
  components: {
    TransactionBody,
  },
});
</script>
