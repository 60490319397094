<template>
    

    <!-- BANNER -->
    <div class="page__content">

        <!-- HEADER -->
        <div class="header">
                    <!-- <form class="header__search">
                        <input class="header__input" type="text" placeholder="Search"/>
                        <button class="header__start">
                            <svg class="icon icon-search">
                                <use xlink:href="img/sprite.svg#icon-search"></use>
                            </svg>
                        </button>
                    </form> -->
                    <router-link class="header__logo" :to="{name: 'portfolio'}">
                        <img src="@/assets/img/logo_main.png" alt="" style="width: 30px;"/>
                    </router-link>
                    <!-- <div class="header__group">
                        <div class="header__item ">
                            <button class="header__head">
                                <svg class="icon icon-notification">
                                    <use xlink:href="img/sprite.svg#icon-notification"></use>
                                </svg>
                            </button>
                           
                        </div>
                        
                        
                    </div> -->
                    <!-- <a class="header__user" href="sign-in.html" >
                        <img src="./img/coins/btc.svg" alt=""/>
                    </a> -->
                    <button class="header__toggle" @click="openSide()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                            <path d="M22 12H3" stroke="#ffffff"></path>
                            <g stroke="#ffffff">
                                <path d="M22 4H13"></path>
                                <path opacity=".301" d="M22 20H13"></path>
                            </g>
                            <path d="M7 7l-5 5 5 5" stroke="#ffffff"></path>
                        </svg>
                    </button>
                </div>

        <div class="wallets mtop-banner" >
                    <div class="wallets__container">
                        
                        <div class="wallets__wrapper" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/bitpokket.appspot.com/o/stk_back2.png?alt=media&token=2c659c02-e57b-4c02-a71e-3b666c471958'); background-size: cover;">
                            <div class="wallets__row">
                                <div class="wallets__total">
                                    <div class="wallets__title wallets__info" style="margin-top: 20px;">Net APY</div>
                                    <div class="wallets__flex">
                                        <div class="wallets__number h1" style="color: #4fbf67; font-size: 70px;">{{netApy}}%</div>
                                        
                                    </div>
                                    
                                </div>
                                
                            </div>
                            <div class="wallets__inner">
                                <div class="wallets__list mm-none" style="margin-top: -50px; margin-bottom: 20px;">
                                    
                                    

                                     

                                    <div class="wallets__item" style="max-width: 200px;">
                                        
                                        <div class="wallets__details">
                                            <div class="wallets__info">Staked assets</div>
                                            <div class="wallets__number h6">$ {{ totalLockedUsd }}</div>
                                            
                                        </div>

                                        
                                    </div>

                                    <div class="wallets__item" style="max-width: 200px;">
                                        
                                        <div class="wallets__details">
                                            <div class="wallets__info">Pending rewards</div>
                                            <div class="wallets__number h6">$ {{ totalRewardUsd }}</div>
                                            
                                        </div>
                                    </div>

                                    <div class="wallets__item" style="max-width: 150px;">
                                        
                                        <div class="wallets__details">
                                            <div class="wallets__info">Active</div>
                                            <div class="wallets__number h6">{{ activeStakes }} / {{ totalStakes }}</div>
                                            
                                        </div>

                                    
                                    </div>

                                    

                                    
                                </div>

                                 <div class="wallets__list dd-none" style="margin-top: 20px; margin-bottom: 20px; width: 100%;">
                                    
                                    <div style="display: flex; justify-content: center; align-items: flex-start; flex-direction: column; margin-left: 10px; margin-right: 10px; width: 100%;">
                                        <div class="wallets__info">Staked </div>
                                        <div class="wallets__number h6">$ {{ totalLockedUsd }}</div>
                                        
                                    </div>

                                   

                                    <div style="display: flex; justify-content: center; align-items: end; flex-direction: column; margin-left: 10px; margin-right: 10px; width: 100%;">
                                        <div class="wallets__info">Rewards</div>
                                        <div class="wallets__number h6">$ {{ totalRewardUsd }}</div>
                                        
                                    </div>

                                    

                                     

                                    <!-- <div class="wallets__item">
                                        
                                        <div class="wallets__details">
                                            <div class="wallets__info">Locked balance</div>
                                            <div class="wallets__number h6">0.256567545 BTC</div>
                                            <div class="wallets__price">3,700.96 USD</div>
                                        </div>
                                    </div> -->

                                    
                                </div>

                                
                            </div>
                            
                        </div>

                        <div class="wallets__wrapper mtop-table" style="background-color: #070707; ">
                            
                            

                            <div class="balances">
                            <div class="balances__title h6">  </div>
                            

                            <div class="popup__tabs js-tabs">
                                <div class="popup__select mobile-show">
                                    <select class="select js-tabs-select">
                                        <option>Locked staking</option>
                                        <option>Flexible staking</option>
                                        <option >Stable staking</option>
                                        <option>ETH 2.0 staking</option>
                                        <option>History</option>
                                        
                                    </select>
                                </div>
                                <div class="popup__nav">
                                    
                                    <a class="popup__link js-tabs-link active" href="#">Locked staking</a>
                                    <a class="popup__link js-tabs-link" href="#">Flexible staking</a>
                                    <a class="popup__link js-tabs-link" href="#">Stable staking</a>
                                    <a class="popup__link js-tabs-link" href="#">ETH 2.0 staking</a>
                                    <a class="popup__link js-tabs-link" href="#">History</a>
                                    
                                </div>

                                <div class="popup__container">

                                    <!-- LOCKED -->
                                    <div class="popup__item js-tabs-item" style="display: block;">

                                        <div class="popup__user">

                                            

                                            <div class="operations__field field js-field">
                                                <!-- <div class="field__label">Search asset name...</div> -->
                                                <div class="field__wrap" style="margin-top: 20px; padding-bottom: 20px;"><input class="field__input js-field-input" placeholder="Search asset name or symbol..."  type="text" v-model="search1" style="height: 50px; padding-bottom: 18px; font-family: 'Neue Machina';" ></div>
                                            </div>
                                            

                                            <!-- <p class="operations__text oppt" style="line-height: 25px; ">Locked staking is when you lock crypto assets for a set period of time to help support the operation of a blockchain. In return for staking your crypto, you earn reward.</p> -->

                                            <!-- <hr style="opacity: 0.1; margin-bottom: 50px;">  -->

                                            <div style="display: flex; justify-content: center; align-items: center; width: 100%; flex-wrap: wrap; margin-bottom: 50px;">

                                                <div v-for="locked in fLockedList" :key="locked.symbol" class="popup popup_setting "  style="width: 300px; border-radius: 20px; box-shadow: 0 4px 8px 0 rgba(44, 45, 44, 0.133), 0 6px 10px 0 rgba(40, 40, 40, 0.19); margin-left: 10px; margin-right: 10px; margin-bottom: 30px;">
            

                                                    <div class="operations__item" style="padding: 30px; margin: 0; ">
                                                        
                                                        <div style="width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;">

                                                            <img :src="locked.image" alt="" style="width: 60px;">
                                                            <h3 style="margin-top: 5px;">{{ locked.symbol }}</h3>
                                                            

                                                        </div>

                                                        <!-- <div class="popup__title h6" style="text-align: center; margin-top: 10px;">Ethereum</div> -->

                                                        <div class="popup__field">

                                                            

                                                            <div style="display: flex; justify-content: center; width: 100%; margin-top: 15px;">
                                                                <div class="popup__label" style="margin-right: 80px;">Duration</div>
                                                                <div class="popup__label">APY</div>
                                                            </div>

                                                            <div class="radio-group" style="margin-top: -5px;">

                                                                <input type="radio" :id="locked.symbol+'30'" :name="'locked'+locked.symbol" value="30" checked>
                                                                <label :for="locked.symbol+'30'"> 
                                                                    <span style="margin-right: 30px; color: #fff;">30 days</span> 
                                                                    <span class="vl2"></span>  
                                                                    <span style="margin-left: 30px;">{{locked.days30}}%</span>
                                                                </label><br>

                                                                <input type="radio" :id="locked.symbol+'90'" :name="'locked'+locked.symbol" value="90">
                                                                <label :for="locked.symbol+'90'"> 
                                                                    <span style="margin-right: 30px; color: #fff;">90 days</span>
                                                                    <span class="vl2"></span>     
                                                                    <span style="margin-left: 30px;">{{locked.days90}}%</span>
                                                                </label><br>

                                                                <input type="radio" :id="locked.symbol+'180'" :name="'locked'+locked.symbol" value="180">
                                                                <label :for="locked.symbol+'180'"> 
                                                                    <span style="margin-right: 24px; color: #fff;">180 days</span>
                                                                    <span class="vl2"></span>    
                                                                    <span style="margin-left: 24px; ">{{locked.days180}}%</span>
                                                                </label><br>

                                                                <input type="radio" :id="locked.symbol+'360'" :name="'locked'+locked.symbol"  value="360">
                                                                <label :for="locked.symbol+'360'"> 
                                                                    <span style="margin-right: 22px; color: #fff;">360 days</span>
                                                                    <span class="vl2"></span>     
                                                                    <span style="margin-left: 22px; ">{{locked.days360}}%</span>
                                                                </label><br>

                                                            </div>

                                                        </div>

                                                        

                                                        <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 40px;">

                                                            <button @click="openStakeModal(lType,locked)" class="btn btn_blue btn_sm" style="min-width: 30px; font-family: 'Neue Machina'; ">Stake {{ locked.symbol }}</button>

                                                        </div>
                                                                                
                                                                                
                                                    </div>

                                                    
                                                </div>

                                               

                                            </div>

                                        </div>
                                        
                                    </div>

                                    <!-- FLEXIBLE -->
                                    <div class="popup__item js-tabs-item">
                                        
                                        <div class="popup__user">

                                            <div class="operations__field field js-field">
                                                <!-- <div class="field__label">Search asset name...</div> -->
                                                <div class="field__wrap"  style="margin-top: 20px; padding-bottom: 20px;">
                                                    <input v-model="search2" class="field__input js-field-input" placeholder="Search asset name..."  type="text" style="height: 50px; padding-bottom: 18px; font-family: 'Neue Machina';" >
                                                </div>
                                            </div>

                                            <div style="display: flex; justify-content: center; align-items: center; width: 100%; flex-wrap: wrap; margin-bottom: 50px;">

                                                
                                                <div v-for="flex in fFlexList" :key="flex.symbol" class="popup popup_setting "  style="width: 300px; border-radius: 20px; box-shadow: 0 4px 8px 0 rgba(44, 45, 44, 0.133), 0 6px 10px 0 rgba(40, 40, 40, 0.19); margin-left: 10px; margin-right: 10px; margin-bottom: 30px;">
            

                                                    <div class="operations__item" style="padding: 30px; margin: 0; ">
                                                
                                                        
                                                        
                                                        <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">

                                                            <img :src="flex.image" alt="" style="width: 50px;">

                                                            <div class="popup__title h5" style="text-align: center; margin-top: 30px; color: #4fbf67; font-weight: 600;">{{flex.apy}}%</div>
                                                        </div>

                                                        <!-- <div class="popup__title h6" style="text-align: center; margin-top: 10px;">Ethereum</div> -->

                                                        

                                                        <div class="popup__field" style="margin-top: 10px; ">
                                                            <!-- <div class="popup__label">Amount</div> -->
                                                            <div class="popup__wrap">
                                                                <input class="popup__input" type="number" :id="'flex'+flex.symbol" placeholder="$0.00" style="font-family: 'Neue Machina'; margin-top: -10px;">
                                                            
                                                            </div>
                                                        </div>

                                                        <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 10px; margin-top: 10px;">
                                                            <p style="color: #808191;">Asset</p>
                                                            <p>{{ flex.name }}</p>
                                                        </div>

                                                        <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 10px; margin-top: 10px;">
                                                            <p style="color: #808191;">Duration</p>
                                                            <p>unlimited</p>
                                                        </div>

                                                        <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 10px; margin-top: 10px;">
                                                            <p style="color: #808191;">Minimum amount</p>
                                                            <p>$3,000</p>
                                                        </div>

                                                        <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 40px;">

                                                            <button @click="flexStake(flex)" class="btn btn_blue btn_sm" style="min-width: 30px; font-family: 'Neue Machina';">Stake {{ flex.name }}</button>

                                                        </div>
                                                                                
                                                                                
                                                    </div>

                                                    
                                                </div>

                                               

                                            </div>

                                            
                                            

                                            
                                        </div>
                                        
                                    </div>


                                    <!-- STABLE -->
                                    <div class="popup__item js-tabs-item">
                                        
                                        <div class="popup__user">

                                            <div class="operations__field field js-field">
                                                <!-- <div class="field__label">Search asset name...</div> -->
                                                <div class="field__wrap" style="margin-top: 20px; padding-bottom: 20px;"><input class="field__input js-field-input" v-model="search3" placeholder="Search asset name..."  type="text" style="height: 50px; padding-bottom: 18px; font-family: 'Neue Machina';" ></div>
                                            </div>

                                            <div style="display: flex; justify-content: center; align-items: center; width: 100%; flex-wrap: wrap; margin-bottom: 50px;">

                                                <div v-for="stable in fStableList" :key="stable.symbol" class="popup popup_setting "  style="width: 300px; border-radius: 20px; box-shadow: 0 4px 8px 0 rgba(44, 45, 44, 0.133), 0 6px 10px 0 rgba(40, 40, 40, 0.19); margin-left: 10px; margin-right: 10px; margin-bottom: 30px;">
            

                                                    <div class="operations__item" style="padding: 30px; margin: 0; ">
                                                        
                                                        <div style="width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;">

                                                            <img :src="stable.image" alt="" style="width: 60px;">
                                                            <h3 style="margin-top: 5px;">{{ stable.symbol }}</h3>
                                                            

                                                        </div>

                                                        <!-- <div class="popup__title h6" style="text-align: center; margin-top: 10px;">Ethereum</div> -->

                                                        <div class="popup__field">

                                                            

                                                            <div style="display: flex; justify-content: center; width: 100%; margin-top: 15px;">
                                                                <div class="popup__label" style="margin-right: 80px;">Duration</div>
                                                                <div class="popup__label">APY</div>
                                                            </div>

                                                            <div class="radio-group" style="margin-top: -5px;">

                                                                <input type="radio" :id="stable.symbol+'30'" :name="'stable'+stable.symbol" value="30" checked>
                                                                <label :for="stable.symbol+'30'"> 
                                                                    <span style="margin-right: 30px; color: #fff;">30 days</span> 
                                                                    <span class="vl2"></span>  
                                                                    <span style="margin-left: 30px;">{{stable.days30}}%</span>
                                                                </label><br>

                                                                <input type="radio" :id="stable.symbol+'90'" :name="'stable'+stable.symbol" value="90">
                                                                <label :for="stable.symbol+'90'"> 
                                                                    <span style="margin-right: 30px; color: #fff;">90 days</span>
                                                                    <span class="vl2"></span>     
                                                                    <span style="margin-left: 30px;">{{stable.days90}}%</span>
                                                                </label><br>

                                                                <input type="radio" :id="stable.symbol+'180'" :name="'stable'+stable.symbol" value="180">
                                                                <label :for="stable.symbol+'180'"> 
                                                                    <span style="margin-right: 24px; color: #fff;">180 days</span>
                                                                    <span class="vl2"></span>    
                                                                    <span style="margin-left: 24px; ">{{stable.days180}}%</span>
                                                                </label><br>

                                                                <input type="radio" :id="stable.symbol+'360'" :name="'stable'+stable.symbol"  value="360">
                                                                <label :for="stable.symbol+'360'"> 
                                                                    <span style="margin-right: 22px; color: #fff;">360 days</span>
                                                                    <span class="vl2"></span>     
                                                                    <span style="margin-left: 22px; ">{{stable.days360}}%</span>
                                                                </label><br>

                                                            </div>

                                                        </div>

                                                        

                                                        <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 40px;">

                                                            <button class="btn btn_blue btn_sm" style="min-width: 30px; font-family: 'Neue Machina';" @click="openStakeModal(sType,stable)">Stake {{ stable.symbol }}</button>

                                                        </div>
                                                                                
                                                                                
                                                    </div>

                                                    
                                                </div>

                                               

                                            </div>

                                            
                                            

                                            
                                        </div>

                                    </div>

                                    <!-- ETH2.0 -->
                                    <div class="popup__item js-tabs-item">
                                        
                                        <div class="popup__user">

                                            <div class="operations__field field js-field">
                                                <!-- <div class="field__label">Search asset name...</div> -->
                                                <div class="field__wrap" style="margin-top: 20px; padding-bottom: 20px;">
                                                    <input v-model="search4" class="field__input js-field-input" placeholder="Search asset name..."  type="text" style="height: 50px; padding-bottom: 18px; font-family: 'Neue Machina';" ></div>
                                            </div>

                                            <div style="display: flex; justify-content: center; align-items: center; width: 100%; flex-wrap: wrap; margin-bottom: 50px;">

                                                
                                                <div v-for="eth in fEthList" :key="eth.symbol" class="popup popup_setting "  style="width: 300px; border-radius: 20px; box-shadow: 0 4px 8px 0 rgba(44, 45, 44, 0.133), 0 6px 10px 0 rgba(40, 40, 40, 0.19); margin-left: 10px; margin-right: 10px; margin-bottom: 30px;">
            

                                                    <div class="operations__item" style="padding: 30px; margin: 0; ">
                                                
                                                        
                                                        
                                                        <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">

                                                            <img :src="eth.image" alt="" style="width: 50px;">

                                                            <div class="popup__title h5" style="text-align: center; margin-top: 30px; color: #4fbf67; font-weight: 600;">{{eth.apy}}%</div>
                                                        </div>

                                                        <!-- <div class="popup__title h6" style="text-align: center; margin-top: 10px;">Ethereum</div> -->

                                                        

                                                        <div class="popup__field" style="margin-top: 10px; ">
                                                            <!-- <div class="popup__label">Amount</div> -->
                                                            <div class="popup__wrap">
                                                                <input class="popup__input" type="number" :id="'eth'+eth.symbol" placeholder="$0.00" style="font-family: 'Neue Machina'; margin-top: -10px;">
                                                            
                                                            </div>
                                                        </div>

                                                        <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 10px; margin-top: 10px;">
                                                            <p style="color: #808191;">Asset</p>
                                                            <p>{{ eth.name }}</p>
                                                        </div>

                                                        <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 10px; margin-top: 10px;">
                                                            <p style="color: #808191;">Duration</p>
                                                            <p>92 days</p>
                                                        </div>

                                                        <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 10px; margin-top: 10px;">
                                                            <p style="color: #808191;">Minimum amount</p>
                                                            <p>$5,000</p>
                                                        </div>

                                                        <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 40px;">

                                                            <button @click="ethStake(eth)" class="btn btn_blue btn_sm" style="min-width: 30px; font-family: 'Neue Machina';">Stake {{ eth.symbol }}</button>

                                                        </div>
                                                                                
                                                                                
                                                    </div>

                                                    
                                                </div>

                                               

                                            </div>

                                            
                                            

                                            
                                        </div>

                                    </div>

                                    <!-- HISTORY -->
                                    <div class="popup__item js-tabs-item">
                                        
                                        <div class="popup__user">

                                            <div class="operations__field field js-field">
                                                <!-- <div class="field__label">Search asset name...</div> -->
                                                <div class="field__wrap" style="margin-top: 20px; padding-bottom: 20px;">
                                                    <input class="field__input js-field-input" placeholder="Search asset name or symbol..."  type="text" style="height: 50px; padding-bottom: 18px; font-family: 'Neue Machina';" v-model="search5">
                                                </div>
                                            </div>

                                            <div style="display: flex; justify-content: center; align-items: center; width: 100%; flex-wrap: wrap; margin-bottom: 50px;">

                                                <div v-for="history in fhistoryList" :key="history.id" class="popup popup_setting "  style="width: 300px; border-radius: 20px; box-shadow: 0 4px 8px 0 rgba(44, 45, 44, 0.133), 0 6px 10px 0 rgba(40, 40, 40, 0.19); margin-left: 10px; margin-right: 10px; margin-top: 10px; margin-bottom: 10px;">
            

                                                    <div class="operations__item" style="padding: 30px; margin: 0; ">
                                                        
                                                        <div style="width: 100%; display: flex; justify-content: center; align-items: center; ">

                                                            <img :src="history.image" alt="" style="width: 60px;">

                                                        </div>

                                                        <div class="popup__title h6" style="text-align: center; margin-top: 10px;">{{ history.name }}</div>

                                                        <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 15px;">

                                                            <p style="color: #808191;">Type</p>
                                                            <p>{{ history.type }}</p>

                                                        </div>

                                                        <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 15px;">

                                                            <p style="color: #808191;">Locked amount</p>
                                                            <p>{{ history.lockedAmount }} {{ history.symbol }}</p>

                                                        </div>

                                                        <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 15px;">

                                                            <p style="color: #808191;">Start date</p>
                                                            <p>{{ history.startDate }}</p>

                                                        </div>

                                                        <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 15px;" v-if="history.type != 'Flexible staking'">

                                                            <p style="color: #808191;">Locked period</p>
                                                            <p>{{ history.lockedPeriod }} days</p>

                                                        </div>

                                                        <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 15px;" v-if="history.type == 'Flexible staking' && history.status == 'in progress'">

                                                            <p style="color: #808191;">Active period</p>
                                                            <p>{{ history.activePeriod }} days</p>

                                                        </div>

                                                        <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 15px;" v-if="history.type != 'Flexible staking'">

                                                            <p style="color: #808191;">End date</p>
                                                            <p>{{ history.endDate }}</p>

                                                        </div>

                                                        <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 15px;">

                                                            <p style="color: #808191;">APY</p>
                                                            <p>{{ history.apy }}%</p>

                                                        </div>

                                                        <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 15px;">

                                                            <p style="color: #808191;">Reward</p>
                                                            <p>{{ history.reward }} {{ history.symbol }}</p>

                                                        </div>

                                                        <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 15px;">

                                                            <p style="color: #808191;">Status</p>
                                                            <div v-if="history.status == 'completed'" style="padding: 5px 10px; border-radius: 15px;" class="promotions__date bg-green-opacity color-green">{{ history.status }}</div>

                                                            <div v-else style="padding: 5px 10px; border-radius: 15px;" class="promotions__date bg-green-opacity color-orange">{{ history.status }}</div>

                                                        </div>

                                                        

                                                        <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 40px;" v-if="history.type == 'Flexible staking' && history.status == 'in progress'">

                                                            <button @click="withdraw(history)" class="btn btn_blue btn_sm" style="min-width: 30px;">Withdraw {{ history.symbol }}</button>

                                                        </div>
                                                                                
                                                                                
                                                    </div>

                                                    
                                                </div>

                                               

                                            </div>
                                            
                                        </div>

                                    </div>
                                    
                                </div>

                            </div>
                            
                            <!-- <div class="balances__btns">
                                <button class="balances__btn btn btn_blue">Load more</button>
                            </div> -->
                        </div>

                            
                        </div>

                       

                    </div>


                    
                </div>

    </div>
                


    <!-- MODALS -->

    <!-- LOADING -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999;" class="loadingModalBg" ref="loadingModal" >

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0; ">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/loading.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px;">Please wait</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ loadingText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/loading.gif" alt="" style="width: 50px; margin-top: -10px; margin-bottom: -20px;">

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- ERROR -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999;" class="loadingModalBg " ref="errorModal">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/error.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #EA4497;">Error!</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ errorText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 10px;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px;" @click="closeE">Okay</button>

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- SUCCESS -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999999;" class="loadingModalBg " ref="successModal">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/success.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #4FBF67;">Success</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ successText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px; margin-top: 10px;" @click="closeS">Okay</button>

                    </div>

                    

                    
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- STAKING MODAL -->
        <div style=" display: flex; justify-content: center; align-items: center;" class="loadingModalBg" ref="stakingModal" >

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="popup__title h6" style="text-align: center;">Stake {{ cName }}</div>

                <button class="mfp-close" @click="closeStakeModal()">x</button>

                <div class="operations__item" style="padding: 0; margin-top: -30px;">
                    

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                        

                        <div class="popup__field field js-field">
                        
                                <div class="field__wrap" style="margin-top: 5px; padding-bottom: 20px;">
                                    <input class="field__input js-field-input" placeholder="$0.00"  type="number" style="height: 50px; padding-bottom: 18px; font-family: 'Neue Machina';" v-model="stakeAmountUsd" @input="sskt()">
                                </div>
                        </div>

                        


                    </div>

                    <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 10px;">
                        <p style="color: #808191;">Amount ({{ cSymbol }})</p>
                        <p>{{ stakeAmount }} {{ cSymbol }}</p>
                    </div>

                    <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 10px;">
                        <p style="color: #808191;">Duration</p>
                        <p>{{ cDays }} days</p>
                    </div>

                    <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 10px;">
                        <p style="color: #808191;">APY</p>
                        <p style="color: #4fbf67;"> {{ cApy }}%</p>
                    </div>

                    <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12px; margin-bottom: 10px;">
                        <p style="color: #808191;">Reward</p>
                        <p >{{ cReward }} {{ cSymbol }}</p>
                    </div>
                    

                    <br>
                    <hr style="opacity: 0.1;">
                    <br>
                    <div class="operations__text" style="line-height: 20px; font-size: 12px;">Please note that the minimum required amount for staking in this package is <strong>${{ cMinimumD }}</strong></div>
                    

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px; margin-top: 10px;" @click="lockedStake()">Confirm</button>

                        

                    </div>

                    

                    
                                            
                                            
                </div>

                
            </div>

        </div>

</template>

<script>

    import { doc, addDoc, collection, query, where, getDocs, getDoc, serverTimestamp, orderBy, updateDoc, increment } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';

    export default {

        data(){
            return{

                successText: "",
                errorText: "",
                loadingText: "",

                search1: "",
                search2: "",
                search3: "",
                search4: "",
                search5: "",

                lType: "locked",
                fType: "flex",
                sType: "stable",
                mainType: "",

                lockedList: [],
                flexibleList: [],
                stableList: [],
                ethList: [],
                historyList:[],
                totalLockedUsd: "0.00",
                totalRewardUsd: "0.00",

                stakeAmount: "",
                stakeAmountUsd: "",

                cPrice: 0,
                cSymbol: "",
                cName: "",
                cImage: "",
                cDays: 0,
                cApy: 0,
                cReward: 0,
                cMinimum: 1000,
                cMinimumD: "1,000",

                totalStakes: 0,
                activeStakes: 0,
                netApy: 0,
                

            }
        },

        computed:{

            fLockedList(){
                return this.lockedList.filter( hist => hist.name.toLowerCase().startsWith(this.search1.toLowerCase()) || hist.symbol.toLowerCase().startsWith(this.search1.toLowerCase()) );
            },

            fFlexList(){
                return this.flexibleList.filter( hist => hist.name.toLowerCase().startsWith(this.search2.toLowerCase()) || hist.symbol.toLowerCase().startsWith(this.search2.toLowerCase()) );
            },

            fStableList(){
                return this.stableList.filter( hist => hist.name.toLowerCase().startsWith(this.search3.toLowerCase()) || hist.symbol.toLowerCase().startsWith(this.search3.toLowerCase()) );
            },

            fEthList(){
                return this.ethList.filter( hist => hist.name.toLowerCase().startsWith(this.search4.toLowerCase()) || hist.symbol.toLowerCase().startsWith(this.search4.toLowerCase()) );
            },

            fhistoryList(){
                return this.historyList.filter( hist => hist.name.toLowerCase().startsWith(this.search4.toLowerCase()) || hist.symbol.toLowerCase().startsWith(this.search5.toLowerCase()) );
            },

        },

        mounted(){

            document.getElementById("portfolio").classList.remove("active");
            document.getElementById("swap").classList.remove("active");
            document.getElementById("staking").classList.add("active");
            //document.getElementById("markets").classList.remove("active");
            document.getElementById("trans").classList.remove("active");

            if(!(sessionStorage.getItem("id"))){
                window.location.replace("https://account.navox.io/sign-in");
            }

            this.mPop();
            //this.tabs();
            this.options();
            this.fieldInput();
            
            this.reward();
        },


        methods:{


            closeS(){
                this.$refs.successModal.classList.remove("bg-active");  
                this.$router.go();
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            fieldInput(){

                $('.js-field-input').focusin(function () {
                    $(this).parents('.js-field').addClass('active');
                });
                $('.js-field-input').focusout(function () {
                    var _this = $(this),
                        field = _this.parents('.js-field'),
                        value = _this.val();
                    if (value == '') {
                        field.removeClass('active');
                    }
                });

            },

            options(){

                let option = $('.js-tabs-select .option');
                let tabs = $('.js-tabs');
                tabs.each(function () {
                    let thisTabs = $(this),
                        nav = thisTabs.find('.js-tabs-link'),
                        option = thisTabs.find('.option'),
                        item = thisTabs.find('.js-tabs-item');
                    nav.on('click', function () {
                        let thisNav = $(this),
                            indexNav = thisNav.index();
                        nav.removeClass('active');
                        thisNav.addClass('active');
                        item.hide();
                        item.eq(indexNav).fadeIn();
                        return false;
                    });
                });

                

                option.on('click', function () {
                    let thisOption = $(this),
                        indexOption = thisOption.index();
                    console.log(indexOption);
                    $('.js-tabs-item').hide();
                    $('.js-tabs-item').eq(indexOption).fadeIn();
                });
            },

            mPop(){

                $('.select').niceSelect();

                $('.js-popup-open').magnificPopup({
                    type: 'inline',
                    fixedContentPos: true,
                    removalDelay: 200,
                    callbacks: {
                        beforeOpen: function beforeOpen() {
                            this.st.mainClass = this.st.el.attr('data-effect');
                        }
                    }
                });
            },

            openSide(){

                document.getElementById("sidebar").classList.add("active")

            },

            async getLockedList(){

                let i = -1;
                const q = query(collection(db, "LockedStaking")); 
                const querySnapshot = await getDocs(q);
                
                querySnapshot.forEach((doc) => {
                    i++;

                    this.lockedList[i] = {
                        name: doc.data().name,
                        symbol: doc.data().symbol,
                        image: doc.data().image,
                        days30: doc.data().days30,
                        days90: doc.data().days90,
                        days180: doc.data().days180,
                        days360: doc.data().days360,
                    }
                    
                })

                this.getFlexibleList();
            },

            async getFlexibleList(){
                

                let i = -1;
                const q = query(collection(db, "FlexibleStaking")); 
                const querySnapshot = await getDocs(q);
                
                querySnapshot.forEach((doc) => {
                    i++;

                    this.flexibleList[i] = {
                        name: doc.data().name,
                        symbol: doc.data().symbol,
                        image: doc.data().image,
                        apy: doc.data().apy,
                    }
                    
                })

                this.getStableList(); 
            },

            async getStableList(){
                
                let i = -1;
                const q = query(collection(db, "StableStaking")); 
                const querySnapshot = await getDocs(q);
                
                querySnapshot.forEach((doc) => {
                    i++;

                    this.stableList[i] = {
                        name: doc.data().name,
                        symbol: doc.data().symbol,
                        image: doc.data().image,
                        days30: doc.data().days30,
                        days90: doc.data().days90,
                        days180: doc.data().days180,
                        days360: doc.data().days360,
                    }
                    
                })

                this.getEthList();
            },

            async getEthList(){
                
                let i = -1;
                const q = query(collection(db, "EthStaking")); 
                const querySnapshot = await getDocs(q);
                
                querySnapshot.forEach((doc) => {
                    i++;

                    this.ethList[i] = {
                        name: doc.data().name,
                        symbol: doc.data().symbol,
                        image: doc.data().image,
                        apy: doc.data().apy,
                    }
                    
                })

                this.$refs.loadingModal.classList.remove("bg-active");
            },

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            async openStakeModal(type, stk){

                this.cSymbol = stk.symbol;
                this.cName = stk.name;
                this.cImage = stk.image;

                //type
                if(type == "locked"){
                    this.mainType = "Locked staking";
                }
                else{
                    this.mainType = "Stable staking";
                }
                
                //get days
                this.cDays = $("input[type='radio'][name="+type+stk.symbol+"]:checked").val();

                //get apy
                if(this.cDays == "30"){
                    this.cApy = stk.days30
                }
                else if(this.cDays == "90"){
                    this.cApy = stk.days90
                }
                else if(this.cDays == "180"){
                    this.cApy = stk.days180
                }
                else{
                    this.cApy = stk.days360
                }

                //get price
                axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+stk.symbol +'&tsyms=USD')
                .then( response => {

                    this.cPrice = Object.values(response.data.RAW)[0].USD.PRICE;
                    
                });

                //reset
                this.stakeAmount = 0;
                this.stakeAmountUsd = 0;
                this.cReward = 0;

                this.$refs.stakingModal.classList.add("bg-active"); 

            },

            sskt(){
                let rm = Number(this.stakeAmountUsd) / this.cPrice;
                this.stakeAmount = this.round(rm, 6);

                let rApy = this.cApy / 100;
                let reward = (Number(this.stakeAmount) * (rApy/365)) * Number(this.cDays);
                this.cReward = this.round(reward, 6);
            },

            async lockedStake(){

                let balance, walletId, newBalance;

                //start loading
                this.loadingText= "Staking "+this.cSymbol+"...";
                this.$refs.loadingModal.classList.add("bg-active");
                
                if(Number(this.stakeAmountUsd) < 1000){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Minimum amount is $1,000";
                    return;
                }

                //check balance
                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), where("symbol", "==", this.cSymbol));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    balance = doc.data().balance;
                    walletId = doc.id;
                })

                if(Number(balance) < Number(this.stakeAmount) ){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Insufficient "+this.cSymbol+" balance";
                    return;
                }

                newBalance = balance - this.stakeAmount;

                const wRef = doc(db, "UserWallets", String(walletId));
                await updateDoc(wRef, {
                    balance: this.round(Number(newBalance), 7)
                });

                //get end date
                let todaySecs = new Date().getTime()/1000;
                let durationSecs = Number(this.cDays) * 24 * 60 * 60;
                let endDateSecs = todaySecs + durationSecs;

                //upload transaction
                await addDoc(collection(db, "UserStakings"), {
                    type: this.mainType,
                    name: this.cName,
                    image: this.cImage,
                    symbol: this.cSymbol,
                    walletId: walletId,
                    userId: sessionStorage.getItem("id"),
                    apy: this.cApy,
                    lockedPeriod: Number(this.cDays),
                    lockedAmount: this.round(Number(this.stakeAmount), 7),
                    reward: this.round(Number(this.cReward), 7),
                    startDate: serverTimestamp(),
                    endDate: Number(endDateSecs),
                    status: "in progress"
                });

                
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = this.cSymbol+" has been staked successfully";

            },

            async flexStake(flex){

                let balance, walletId, newBalance, stakeAmount, stakeAmountUsd, price;

                stakeAmountUsd = document.getElementById("flex"+flex.symbol).value;

                //start loading
                this.loadingText= "Staking "+flex.symbol+"...";
                this.$refs.loadingModal.classList.add("bg-active");
                
                if(Number(stakeAmountUsd) < 3000){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Minimum amount is $3,000";
                    return;
                }

                //get price
                await axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+flex.symbol +'&tsyms=USD')
                .then( response => {

                    price = Object.values(response.data.RAW)[0].USD.PRICE;
                    
                });

                stakeAmount = Number(stakeAmountUsd) / price;

                //check balance
                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), where("symbol", "==", flex.symbol));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    balance = doc.data().balance;
                    walletId = doc.id;
                })

                if(Number(balance) < Number(stakeAmount) ){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Insufficient "+flex.symbol+" balance";
                    return;
                }

                newBalance = balance - stakeAmount;

                const wRef = doc(db, "UserWallets", String(walletId));
                await updateDoc(wRef, {
                    balance: this.round(Number(newBalance), 7)
                });

                //upload transaction
                await addDoc(collection(db, "UserStakings"), {
                    type: "Flexible staking",
                    name: flex.name,
                    image: flex.image,
                    symbol: flex.symbol,
                    walletId: walletId,
                    userId: sessionStorage.getItem("id"),
                    apy: Number(flex.apy),
                    lockedPeriod: 0,
                    lockedAmount: this.round(Number(stakeAmount), 7),
                    reward: 0,
                    startDate: serverTimestamp(),
                    endDate: 0,
                    status: "in progress"
                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = flex.symbol+" has been staked successfully";

            },

            async ethStake(eth){

                let balance, walletId, newBalance, stakeAmount, stakeAmountUsd, price, days, reward;

                stakeAmountUsd = document.getElementById("eth"+eth.symbol).value;

                //start loading
                this.loadingText= "Staking "+eth.symbol+"...";
                this.$refs.loadingModal.classList.add("bg-active");
                
                if(Number(stakeAmountUsd) < 5000){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Minimum amount is $5,000";
                    return;
                }

                //get price
                await axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+eth.symbol +'&tsyms=USD')
                .then( response => {

                    price = Object.values(response.data.RAW)[0].USD.PRICE;
                    
                });

                stakeAmount = Number(stakeAmountUsd) / price;

                //check balance
                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), where("symbol", "==", eth.symbol));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    balance = doc.data().balance;
                    walletId = doc.id;
                })

                if(Number(balance) < Number(stakeAmount) ){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Insufficient "+eth.symbol+" balance";
                    return;
                }

                //get reward
                days = 92;

                let rApy = eth.apy / 100;
                let mReward = (Number(stakeAmount) * (rApy/365)) * days;
                reward = this.round(mReward, 6);

                //get end date
                let todaySecs = new Date().getTime()/1000;
                let durationSecs = days * 24 * 60 * 60;
                let endDateSecs = todaySecs + durationSecs;

                newBalance = balance - stakeAmount;

                const wRef = doc(db, "UserWallets", String(walletId));
                await updateDoc(wRef, {
                    balance: this.round(Number(newBalance), 7)
                });

                //upload transaction
                await addDoc(collection(db, "UserStakings"), {
                    type: "ETH 2.0 staking",
                    name: eth.name,
                    image: eth.image,
                    symbol: eth.symbol,
                    walletId: walletId,
                    userId: sessionStorage.getItem("id"),
                    apy: Number(eth.apy),
                    lockedPeriod: days,
                    lockedAmount: this.round(Number(stakeAmount), 7),
                    reward: reward,
                    startDate: serverTimestamp(),
                    endDate: endDateSecs,
                    status: "in progress"
                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = eth.symbol+" has been staked successfully";

            },

            closeStakeModal(){

                this.$refs.stakingModal.classList.remove("bg-active"); 

            },

            async getHistory(){

                const q = query(collection(db, "UserStakings"), where("userId", "==", sessionStorage.getItem("id")), orderBy("startDate", "desc")); 

                const querySnapshot = await getDocs(q);

                let i = -1;
                let a2, a3, days;
                let activeTemp = 0;
                let completedTemp = 0;
                

                let todaySecs = new Date().getTime()/1000;
                let mReward = 0;

                querySnapshot.forEach((doc) => {
                    i++;

                    this.totalStakes++;

                    a2 = String(new Date(Number(doc.data().startDate.seconds) * 1000));
                    a3 = String(new Date(Number(doc.data().endDate) * 1000));
                    mReward = Number(doc.data().reward);
                    
                    //count active
                    if(doc.data().status == "in progress"){
                        this.activeStakes++;
                    }
                    else{
                        completedTemp++;
                    }

                    //FS rewards
                    if(doc.data().type == "Flexible staking" && doc.data().status == 'in progress'){
                        let checkSecs = todaySecs - doc.data().startDate.seconds;
                        days = checkSecs/(60 * 60 * 24);

                        let rApy = doc.data().apy/100;
                        mReward = doc.data().lockedAmount * (rApy/365) * Number(days);
                    }

                    this.historyList[i] = {
                        number: i,
                        id: doc.id,
                        apy: Number(doc.data().apy),
                        endDateSecs: Number(doc.data().endDate),
                        endDate: a3.slice(4, -36),
                        symbol: doc.data().symbol,
                        image: doc.data().image,
                        lockedAmount: Number(doc.data().lockedAmount),
                        lockedPeriod: Number(doc.data().lockedPeriod),
                        activePeriod: this.round(days, 0),
                        name: doc.data().name,
                        reward: this.round(mReward, 7),
                        startDate: a2.slice(4, -36),
                        startDateSecs: Number(doc.data().startDate.seconds),
                        status: doc.data().status,
                        type: doc.data().type,
                        userId: doc.data().userId,
                        walletId: doc.data().walletId,
                    }
                    
                })

                if(this.historyList.length > 0){
                    this.getTotal();
                }
                
                this.getLockedList();

            },

            async getTotal(){

                let temp;
                let temp2 = 0;
    
                let temp3;
                let temp4 = 0;

                let totalUsd;
                let totalLockedUsd;

                let num = 0;
                let totalApy = 0

                for(let i=0; i< this.historyList.length; i++){

                    num++
                   
                    if(this.historyList[i].status == 'in progress'){

                        let tempSym;
                        tempSym = this.historyList[i].symbol;

                        await axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+tempSym+'&tsyms=USD')
                        .then( response => {

                            let price = Number(Object.values(response.data.RAW)[0].USD.PRICE);

                            //rewards
                            let reward = this.historyList[i].reward;
                            temp = price * reward;
                            temp2 += temp; 
                            totalUsd = this.round(temp2, 2);
                            
                            if(totalUsd == 0){
                                this.totalRewardUsd = "0.00";
                            }
                            else{
                                this.totalRewardUsd = totalUsd.toLocaleString("us-US");
                            }

                            //locked
                            let locked = this.historyList[i].lockedAmount;
                            temp3 = price * locked;
                            temp4 += temp3; 
                            totalLockedUsd = this.round(temp4, 2);
                            if(totalLockedUsd == 0){
                                this.totalLockedUsd = "0.00";
                            }
                            else{
                                this.totalLockedUsd = totalLockedUsd.toLocaleString("us-US");
                            }

                            //apy
                            totalApy +=  this.historyList[i].apy;

                        }); 
                    }

                }

                let tempApy = totalApy / num;
                this.netApy = tempApy.toLocaleString("us-Us");
                

            },

            async reward(){

                //start loading
                this.loadingText= "Loading assets...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                //get staking trans
                let todaySecs = new Date().getTime()/1000;

                const q = query(collection(db, "UserStakings"), where("userId", "==", sessionStorage.getItem("id"))); 
                const querySnapshot = await getDocs(q);

                if(querySnapshot.docs.length > 0){
                    querySnapshot.forEach((doc) => {

                        //check endDate;
                        if(doc.data().type != "Flexible staking" && doc.data().status == "in progress"){
                            if( todaySecs > doc.data().endDate){

                                //staking complete
                                this.updateStaking(doc.data().walletId, doc.data().reward, doc.data().lockedAmount, doc.id);
                                
                                
                            }
                        }

                    })
                }

                this.getHistory();


            },

            async updateStaking(walletId, reward, lockedAmount, docId){

                let balance, newBalance;

                //get balance
                const walletRef = doc(db, "UserWallets", walletId);
                const walletSnap = await getDoc(walletRef);

                if (walletSnap.exists()) {
                    balance = walletSnap.data().balance;
                }

                else{
                    console.log("NO WALLET WITH THIS ID");
                    return;
                }

                //update balance
                newBalance = balance + reward + lockedAmount;
                await updateDoc(walletRef, {
                    balance: this.round(Number(newBalance), 7)
                });

                const stakeRef = doc(db, "UserStakings", docId);
                await updateDoc(stakeRef, {
                    status: "completed"
                });
            },

            async withdraw(history){

                this.loadingText= "Withdrawing "+history.symbol+"...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                let balance = 0;

                //check days
                let todaySecs = new Date().getTime()/1000;
                let checkSecs = todaySecs - history.startDateSecs;
                
                let days = checkSecs/(60 * 60 * 24);

                if(days < 1){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Minimum durartion for withdrawal on Flexible staking is 1 day";
                    return;
                }


                //get reward
                let rApy = history.apy/100;
                let reward = history.lockedAmount * (rApy/365) * Number(days);

                //get balance
                const walletRef = doc(db, "UserWallets", history.walletId);
                const walletSnap = await getDoc(walletRef);

                if (walletSnap.exists()) {
                    balance = walletSnap.data().balance;
                }

                //update balance
                let newBalance = balance + reward + history.lockedAmount;
                await updateDoc(walletRef, {
                    balance: this.round(Number(newBalance), 7)
                });

                //update status
                const stakeRef = doc(db, "UserStakings", history.id);
                await updateDoc(stakeRef, {
                    status: "completed",
                    reward: reward,
                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = history.symbol+" withdrawal completed. Your portfolio balance has been updated";
            }

        }
        
    }
</script>

